import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  RadioGroup,
  FormLabel,
  Box,
  IconButton,
} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from '@mui/icons-material/Print';

import CustomizedDialogs from "../ui/modal";
import { addImage, useGet, usePost, usePut, useDelete } from "../../hooks/useFetch";
import { Order_API, USER_ADDRESS_API, GET, POST, PUT, DELETE } from "../../api/config";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import { pascalCase, wordWrap, formatDate } from "../../utils/helper";

import LoaderContext from "../../store/loader-context";

import NumberInput from '../ui/number-input';

import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  ImageDimensionsValidator,
} from "use-file-picker/validators";
import { useImperativeFilePicker } from "use-file-picker";


function Order() {
  const { loader, showLoader, hideLoader } = useContext(LoaderContext);

  const [orders, setOrders] = useState([]);
  const [action, setAction] = useState("view");
  const [rowData, setRowData] = useState(null);
  const [orderHTML, setOrderHTML] = useState(null);
  const [modal, setModal] = useState(false);
  const [orderDetailsLoadStatus, setOrderDetailsLoadStatus] = useState(false);
  const [userAddresses, setUserAddresses] = useState([]);
  const [shippingAddress, setShippingAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");

  const [productInfo, setProductInfo] = useState(null);

  const [totalOrderQty, setTotalOrderQty] = useState(0);
  const [subTotal, setSubTotal] = useState(0.00);
  const [chargeAmount, setChargeAmount] = useState(0.00);
  const [salesTax, setSalesTax] = useState(0.00);
  const [countryTax, setCountryTax] = useState(0.00);
  const [rushFee, setRushFee] = useState(0.00);
  const [greekLicensingFeeText, setGreekLicensingFeeText] = useState("");
  const [greekLicensingFee, setGreekLicensingFee] = useState(0.00);
  const [greekLicensingFee2Text, setGreekLicensingFee2Text] = useState("");
  const [greekLicensingFee2, setGreekLicensingFee2] = useState(0.00);
  const [greekLicensingFee3Text, setGreekLicensingFee3Text] = useState("");
  const [greekLicensingFee3, setGreekLicensingFee3] = useState(0.00);
  const [shippingList, setShippingList] = useState([]);
  const [shippingType, setShippingType] = useState("");
  const [shippingCharge, setShippingCharge] = useState(0.00);
  const [customerSignature, setCustomerSignature] = useState(false);
  const [customerSignatureCharge, setCustomerSignatureCharge] = useState(2.00);
  const [adjustmentDetailsText, setAdjustmentDetailsText] = useState("");
  const [adjustmentPrice, setAdjustmentPrice] = useState(0.00);
  const [total, setTotal] = useState(0.00);
  
  const [promotionCode, setPromotionCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0.00);
  const [netAmount, setNetAmount] = useState(0.00);
  const [customFiles, setCustomFiles] = useState([]);

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: ordersData, refresh: refreshOrders } = useGet(Order_API[GET], {
    apiCall: "onload",
  });
  const { refresh: postData } = usePost(Order_API[POST]);
  const { data: ordersUpdateData, refresh: putData } = usePut(Order_API[PUT]);
  const { refresh: delData } = useDelete(Order_API[DELETE]);

  const { data: userAddressData, refresh: refreshUserAddress } = useGet(USER_ADDRESS_API[GET]);

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (ordersData) {
      console.log("ordersData : ", ordersData);
      setOrders(ordersData.data || []);
      setOrderDetailsLoadStatus(true);
    }

    if (ordersUpdateData && ordersUpdateData.data != null) {
      console.log("ordersUpdateData : ", ordersUpdateData);
      setOrderHTML(ordersUpdateData.data.html || null);
    }
  }, [ordersData, ordersUpdateData]);
  

  useEffect(() => {
    if (rowData && typeof rowData._id !== "undefined") {
      hideLoader();

      //console.log("userAddresses===", userAddresses);
      //if (userAddresses.length == 0) {
        /* call user address api */
        console.log("refreshUserAddress : "+`?userId=${rowData.userId}`);
        refreshUserAddress(`?userId=${rowData.userId}&addressType=billing`);
      //}
      console.log("useEffect rowdata : ", rowData);

      /* set address info */
      setShippingAddress(rowData.shippingAddressId);
      setBillingAddress(rowData.billingAddressId);

      /* set shipping list */
      setShippingList([
        {
          "type" : "Standard Shipping",
          "cost" : rowData.shippingRate.standard
        },
        {
          "type" : "Priority Shipping",
          "cost" : rowData.shippingRate.priority
        },
        {
          "type" : "Express Shipping",
          "cost" : rowData.shippingRate.express
        },
        {
          "type" : "Customer Pickup",
          "cost" : 0
        },
      ]);

      /* set additional field value */
      if (rowData.additionalData) {
        
        setPromotionCode(typeof rowData.additionalData.promotionCode === "undefined" ? "" : rowData.additionalData.promotionCode);
        setDiscountAmount(typeof rowData.additionalData.totalOrderDiscount === "undefined" ? 0.00 : priceFormat(rowData.additionalData.totalOrderDiscount));

        setSalesTax(typeof rowData.additionalData.totalOrderSalesTax === "undefined" ? 0.00 : priceFormat(rowData.additionalData.totalOrderSalesTax));
        setCountryTax(typeof rowData.additionalData.totalOrderCountryTax === "undefined" ? 0.00 : priceFormat(rowData.additionalData.totalOrderCountryTax));
        setRushFee(typeof rowData.additionalData.totalOrderRushFeeTax === "undefined" ? 0.00 : priceFormat(rowData.additionalData.totalOrderRushFeeTax));

        setGreekLicensingFeeText(typeof rowData.additionalData.totalOrderGreekLicensingFeeText === "undefined" ? "" : rowData.additionalData.totalOrderGreekLicensingFeeText);
        setGreekLicensingFee(typeof rowData.additionalData.totalOrderGreekLicensingFee === "undefined" ? 0.00 : priceFormat(rowData.additionalData.totalOrderGreekLicensingFee));
        setGreekLicensingFee2Text(typeof rowData.additionalData.totalOrderGreekLicensingFee2Text === "undefined" ? "" : rowData.additionalData.totalOrderGreekLicensingFee2Text);
        setGreekLicensingFee2(typeof rowData.additionalData.totalOrderGreekLicensingFee2 === "undefined" ? 0.00 : priceFormat(rowData.additionalData.totalOrderGreekLicensingFee2));
        setGreekLicensingFee3Text(typeof rowData.additionalData.totalOrderGreekLicensingFee3Text === "undefined" ? "" : rowData.additionalData.totalOrderGreekLicensingFee3Text);
        setGreekLicensingFee3(typeof rowData.additionalData.totalOrderGreekLicensingFee3 === "undefined" ? 0.00 : priceFormat(rowData.additionalData.totalOrderGreekLicensingFee3));
        setChargeAmount(typeof rowData.additionalData.totalOrderAdditionalCharge === "undefined" ? 0.00 : priceFormat(rowData.additionalData.totalOrderAdditionalCharge));
        
        setShippingType(typeof rowData.additionalData.totalOrderShippingType === "undefined" ? (typeof rowData.additionalData.shippingType === "undefined" ? "" : rowData.additionalData.shippingType):rowData.additionalData.totalOrderShippingType);
        setShippingCharge(typeof rowData.additionalData.totalOrderShippingCharge === "undefined" ? (typeof rowData.additionalData.shippingPrice === "undefined" ? 0.00 : priceFormat(rowData.additionalData.shippingPrice)) : priceFormat(rowData.additionalData.totalOrderShippingCharge));

        setCustomerSignature(typeof rowData.additionalData.isPickupSignatureNeeded === "undefined" ? false : Boolean(rowData.additionalData.isPickupSignatureNeeded));
        setCustomerSignatureCharge(typeof rowData.additionalData.isPickupSignatureNeeded !== "undefined" && rowData.additionalData.isPickupSignatureNeeded == true?(rowData.additionalData.isPickupSignatureCharge === "undefined" ? 2.00 : priceFormat(rowData.additionalData.isPickupSignatureCharge)):0.00);
      
        setAdjustmentDetailsText(typeof rowData.additionalData.totalOrderAdjustmentDetailsText === "undefined" ? "" : rowData.additionalData.totalOrderAdjustmentDetailsText);
        setAdjustmentPrice(typeof rowData.additionalData.totalOrderAdjustmentPrice === "undefined" ? 0.00 : priceFormat(rowData.additionalData.totalOrderAdjustmentPrice));
      }

      /* calculate totals */
      const totalOrderQty = totalOrderQtyCalculation(rowData);
      const subTotalAmount = calculateSubTotalAmount(rowData);
      const totalOrderAmount = calculateTotalAmount(rowData);
      const netAmount = calculateNetAmount(rowData);
      setTotalOrderQty(totalOrderQty);
      setSubTotal(subTotalAmount);
      setTotal(totalOrderAmount);
      setNetAmount(netAmount);
    } else {
      setShippingAddress("");
      setBillingAddress("");
      //setProductInfo(null);
    }
  }, [rowData]);

  useEffect(() => {
    if (rowData && typeof rowData._id !== "undefined") {
      console.log("after rowData ---------------");
      //let chargeVal = typeof rowData.additionalData.totalOrderAdditionalCharge !== "undefined"?priceFormat(rowData.additionalData.totalOrderAdditionalCharge):0.00;
      //console.log("chargeVal : ",chargeVal);
      //let discountVal = typeof rowData.additionalData.totalOrderDiscount !== "undefined"?priceFormat(rowData.additionalData.totalOrderDiscount):0.00;
      //console.log("discountVal : ",discountVal);
      //let netAmountVal = calculateTotalAmount(rowData);

      let totalAmount = calculateTotalAmount(rowData);
      console.log("totalAmount : ", totalAmount);
      setTotal(totalAmount);

      let netAmountVal = calculateNetAmount(rowData);
      console.log("netAmountVal : ", netAmountVal);
      //setChargeAmount(chargeVal);
      //setDiscountAmount(discountVal);
      setNetAmount(netAmountVal);
    }
  }, [subTotal]);

  useEffect(() => {
    console.log("userAddressData : ", userAddressData);
    if (userAddressData) {
      console.log("userAddress actual Data : ", userAddressData.data.filter(itm => itm.userId == rowData.userId));
      setUserAddresses(userAddressData.data.filter(itm => itm.userId == rowData.userId));
    } else {
      
    }
  }, [userAddressData])

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const approveRowData = (params) => {
    console.log(params.row);
    setRowData(params.row);
    setAction("approve");
    setModal(true);
  };

  const rejectRowData = (params) => {
    console.log(params.row);
    setRowData(params.row);
    setAction("reject");
    setModal(true);
  };

  const reorderEmbroidaryInRowData = (params) => {
    params.row = {
      ...params.row,
      productList: params.row.productList.map(function (prdItem) {
        return {
          ...prdItem,
          customizedProducts: {
            ...prdItem.customizedProducts,
            embroidaryId: prdItem.customizedProducts.embroidaryId.map(function (emdryItem, i) {
              let type = convertToSideValue(emdryItem);
              console.log("type : ", type);
              switch (type) {
                case "crest_and_images":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "chest":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "front":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "back":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "arm_left":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "arm_right":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "left_cuff":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "right_cuff":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "on_collar":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "collar_flipped_up":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "hood_forward":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "hood_backward":
                  return {
                    ...emdryItem,
                    rank: (i + 1)
                  };
                  break;
                case "hood":
                  return {
                    ...emdryItem,
                    rank: (prdItem.customizedProducts.embroidaryId.length + 1)
                  };
                break;
              }
            }).sort((a, b) => (a.rank > b.rank) ? 1 : ((b.rank > a.rank) ? -1 : 0))
          }
        };
      })
    };

    return params; 
  }

  const editRowData = (params) => {
    console.log("Action : Edit");
    console.log(params.row);
    if (!orderDetailsLoadStatus) {
      //alert("Please wait refreshing data...");
      showLoader();
    } else {
      hideLoader();

      /* load preview data */
      const data = {
        _id: params.row._id,
        getOrderHTML: true
      };
     
      console.log("Order HTML DATA Payload: ", data);
      putData({ data }, {});

      /* reorder embroidary */
      params = reorderEmbroidaryInRowData(params);
      setRowData(null);
      //console.log("params.row : ", params.row); return false;
      setRowData(params.row);
      setAction("edit");
      setModal(true);
    }
  };

  const deleteRowData = (params) => {
    console.log(params.row);
    /*setRowData(params.row);
    setModal(true);
    setAction("delete");*/
  };

  const printRowData = (params) => {
    
    console.log("Action : Print");
    console.log(params.row);
    if (!orderDetailsLoadStatus) {
      //alert("Please wait refreshing data...");
      showLoader();
    } else {
      hideLoader();

      /* load preview data */
      const data = {
        _id: params.row._id,
        getOrderHTML: true,
        showSuccessMsg : false
      };
     
      console.log("Order HTML DATA Payload: ", data);
      putData({ data }, {});

      /* reorder embroidary */
      params = reorderEmbroidaryInRowData(params);
      
      //console.log("params.row : ", params.row); return false;
      setRowData(params.row);
      setAction("print");
      setModal(true);

      setTimeout(() => {
        window.print();
        window.onfocus = () => { 
          //setTimeout(function () { 
            setModal(false);
          //}, 500); 
        }
        window.onafterprint = () => {
          setModal(false);
        } 
      }, 3000);
    }
  }

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 300,
      align: "right",
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative"
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.update == true &&
            !params.row.orderAccepted &&
            !params.row.orderCancelled && (
              <IconButton
                aria-label="approve"
                color="primary"
                onClick={() => approveRowData(params)}
              >
                <CheckIcon />
              </IconButton>
            )}
          {permissionData.delete == true &&

            !params.row.orderCancelled && ( /* !params.row.orderAccepted && */
              <IconButton
                aria-label="reject"
                color="outlined"
                onClick={() => rejectRowData(params)}
              >
                <CloseIcon />
              </IconButton>
            )}
          <IconButton
            aria-label="print"
            color="outlined"
            onClick={() => printRowData(params)}
          >
            <PrintIcon />
          </IconButton>
        </Box>
      ),
    },
    { field: "orderId", headerName: "Order Id", width: 200 },
    { field: "userId", headerName: "User Id", width: 250 },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 150,
      align: "center",
      renderCell: (params) => {
        return <span>${typeof params.row.additionalData !== "undefined" && typeof params.row.additionalData.netOrderAmount !== "undefined" ? priceFormat(params.row.additionalData.netOrderAmount) : priceFormat(params.value)}</span>
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: true,
      valueGetter: (params) =>
        `${!params.row.orderAccepted && !params.row.orderCancelled
          ? pascalCase(params?.row?.status)
          : params.row.orderAccepted
            ? "Approved"
            : "Rejected"
        }`,
    },
    {
      field: "placedAt",
      headerName: "Placed At",
      width: 200,
      sortable: true,
      valueGetter: (params) => {
        const date = formatDate(new Date(params?.row?.placedAt));
        return `${date}`;
      }
    }
  ];

  const convertToSideValue = (side) => {
    if(typeof side === "undefined" || side == null) return '';
    console.log("convertToSideValue side : ", side);
    let sideVal = side.name.trim().toLowerCase().replace(/\s/g, "_");

    if (sideVal == "additional_text_to_greek_letters") {
      sideVal = side.name.trim().toLowerCase().replace(/\s/g, "_");
    }

    console.log("sideVal : ", sideVal);
    let actualSideVal = "";
    switch (sideVal) {
      case "front":
        actualSideVal = "front";
        break;
      case "chest":
        actualSideVal = "front";
        break;
      case "left_chest":
        actualSideVal = "front";
        break;
      case "right_chest":
        actualSideVal = "front";
        break;
      case "back":
        actualSideVal = "back";
        break;
      case "crest_and_images(price_varies_with_size_and_design)":
        actualSideVal = "crest_and_images";
        break;
      case "left_arm":
        actualSideVal = "arm_left";
        break;
      case "right_arm":
        actualSideVal = "arm_right";
        break;
      case "left_cuff":
        actualSideVal = "left_cuff";
        break;
      case "right_cuff":
        actualSideVal = "right_cuff";
        break;   
      case "on_collar":
        actualSideVal = "on_collar";
        break;
      case "collar_flipped_up":
        actualSideVal = "collar_flipped_up";
        break;
      case "hood":
        actualSideVal = "hood";
        break;
      case "hood_forward":
        actualSideVal = "hood_forward";
        break;
      case "hood_backward":
        actualSideVal = "hood_backward";
        break;
      default:
        actualSideVal = sideVal;
        break;
    }

    return actualSideVal;
  }

  //setSubTotal
  const totalOrderQtyCalculation = (orderObj) => {
    return orderObj.productList.reduce((total, prdct) => total + parseInt(prdct.totalQuantity), 0);
  }

  const calculateSubTotalAmount = (orderObj) => {
    return priceFormat(orderObj.productList.reduce((total, prdct) => total + parseFloat(totalPriceCalculation(prdct)), 0.00));
  }

  const totalQtyCalculation = (productObj) => {
    return productObj.customizedProducts.colors['size&quantity'].reduce((sum, sizeInfo) => sum += parseInt(sizeInfo.quantity), 0);
  }

  const totalPriceCalculation = (productObj) => {
    const basePrice = parseFloat(productObj.productId.price);
    let totalPrice = 0;

    /* size & qty */
    console.log(productObj.customizedProducts.colors['size&quantity']);
    totalPrice = productObj.customizedProducts.colors['size&quantity'].reduce((sum, sizeInfo) => sum += basePrice + (sizeInfo.quantity * parseFloat(sizeInfo.sizePrice)), 0);
    console.log("totalPrice after siz&qty : ", totalPrice);
    let totalQty = productObj.customizedProducts.colors['size&quantity'].reduce((sum, sizeInfo) => sum += parseInt(sizeInfo.quantity), 0);

    console.log("totalQty after siz&qty : ", totalQty);

    /* applique back background */
    const back_background_backgroundMaterial_price = productObj.customizedProducts.appliqueId.back.background.backgroundMaterial && typeof productObj.customizedProducts.appliqueId.back.background.backgroundMaterial.price !== "undefined" ? productObj.customizedProducts.appliqueId.back.background.backgroundMaterial.price : 0;
    const back_background_backgroundColor_price = productObj.customizedProducts.appliqueId.back.background.backgroundColor && typeof productObj.customizedProducts.appliqueId.back.background.backgroundColor.price !== "undefined" ? productObj.customizedProducts.appliqueId.back.background.backgroundColor.price : 0;
    const back_background_threadColor_price = productObj.customizedProducts.appliqueId.back.background.threadColor && typeof productObj.customizedProducts.appliqueId.back.background.threadColor.price !== "undefined" ? productObj.customizedProducts.appliqueId.back.background.threadColor.price : 0;
    const total_back_background_backgroundMaterial_price = totalQty * parseFloat(back_background_backgroundMaterial_price);
    const total_back_background_backgroundColor_price = totalQty * parseFloat(back_background_backgroundColor_price);
    const total_back_background_threadColor_price = totalQty * parseFloat(back_background_threadColor_price);

    /* applique back foreground */
    const back_foreground_foregroundMaterial_price = productObj.customizedProducts.appliqueId.back.foreground.foregroundMaterial && typeof productObj.customizedProducts.appliqueId.back.foreground.foregroundMaterial.price !== "undefined" ? productObj.customizedProducts.appliqueId.back.foreground.foregroundMaterial.price : 0;
    const back_foreground_foregroundColor_price = productObj.customizedProducts.appliqueId.back.foreground.foregroundColor && typeof productObj.customizedProducts.appliqueId.back.foreground.foregroundColor.price !== "undefined" ? productObj.customizedProducts.appliqueId.back.foreground.foregroundColor.price : 0;
    const back_foreground_threadColor_price = productObj.customizedProducts.appliqueId.back.foreground.threadColor && typeof productObj.customizedProducts.appliqueId.back.foreground.threadColor.price !== "undefined" ? productObj.customizedProducts.appliqueId.back.foreground.threadColor.price : 0;
    const total_back_foreground_foregroundMaterial_price = totalQty * parseFloat(back_foreground_foregroundMaterial_price);
    const total_back_foreground_foregroundColor_price = totalQty * parseFloat(back_foreground_foregroundColor_price);
    const total_back_foreground_threadColor_price = totalQty * parseFloat(back_foreground_threadColor_price);
    /* console.log("back_foreground_foregroundMaterial_price : ", back_foreground_foregroundColor_price);
    console.log("back_foreground_foregroundColor_price : ", back_background_backgroundColor_price);
    console.log("back_foreground_threadColor_price : ", back_foreground_threadColor_price); */
    /* applique back heatSealedLetter */
    const back_heatSealedLetter_price = productObj.customizedProducts.appliqueId.back.heatSealedLetter && typeof productObj.customizedProducts.appliqueId.back.heatSealedLetter.cost !== "undefined" ? productObj.customizedProducts.appliqueId.back.heatSealedLetter.cost : 0;
    const total_back_heatSealedLetter_price = totalQty * parseFloat(back_heatSealedLetter_price);
    /* applique back stitchingType */
    const back_stitchingType_price = productObj.customizedProducts.appliqueId.back.stitchingType && typeof productObj.customizedProducts.appliqueId.back.stitchingType.price !== "undefined" ? productObj.customizedProducts.appliqueId.back.stitchingType.price : 0;
    const total_back_stitchingType_price = totalQty * parseFloat(back_stitchingType_price);

    /* applique front background */
    const front_background_backgroundMaterial_price = productObj.customizedProducts.appliqueId.front.background.backgroundMaterial && typeof productObj.customizedProducts.appliqueId.front.background.backgroundMaterial.price !== "undefined" ? productObj.customizedProducts.appliqueId.front.background.backgroundMaterial.price : 0;
    const front_background_backgroundColor_price = productObj.customizedProducts.appliqueId.front.background.backgroundColor && typeof productObj.customizedProducts.appliqueId.front.background.backgroundColor.price !== "undefined" ? productObj.customizedProducts.appliqueId.front.background.backgroundColor.price : 0;
    const front_background_threadColor_price = productObj.customizedProducts.appliqueId.front.background.threadColor && typeof productObj.customizedProducts.appliqueId.front.background.threadColor.price !== "undefined" ? productObj.customizedProducts.appliqueId.front.background.threadColor.price : 0;
    const total_front_background_backgroundMaterial_price = totalQty * parseFloat(front_background_backgroundMaterial_price);
    const total_front_background_backgroundColor_price = totalQty * parseFloat(front_background_backgroundColor_price);
    const total_front_background_threadColor_price = totalQty * parseFloat(front_background_threadColor_price);
    /* applique front foreground */
    const front_foreground_foregroundMaterial_price = productObj.customizedProducts.appliqueId.front.foreground.foregroundMaterial && typeof productObj.customizedProducts.appliqueId.front.foreground.foregroundMaterial.price !== "undefined" ? productObj.customizedProducts.appliqueId.front.foreground.foregroundMaterial.price : 0;
    const front_foreground_foregroundColor_price = productObj.customizedProducts.appliqueId.front.foreground.foregroundColor && typeof productObj.customizedProducts.appliqueId.front.foreground.foregroundColor.price !== "undefined" ? productObj.customizedProducts.appliqueId.front.foreground.foregroundColor.price : 0;
    const front_foreground_threadColor_price = productObj.customizedProducts.appliqueId.front.foreground.threadColor && typeof productObj.customizedProducts.appliqueId.front.foreground.threadColor.price !== "undefined" ? productObj.customizedProducts.appliqueId.front.foreground.threadColor.price : 0;
    const total_front_foreground_foregroundMaterial_price = totalQty * parseFloat(front_foreground_foregroundMaterial_price);
    const total_front_foreground_foregroundColor_price = totalQty * parseFloat(front_foreground_foregroundColor_price);
    const total_front_foreground_threadColor_price = totalQty * parseFloat(front_foreground_threadColor_price);
    /* applique front heatSealedLetter */
    const front_heatSealedLetter_price = productObj.customizedProducts.appliqueId.front.heatSealedLetter && typeof productObj.customizedProducts.appliqueId.front.heatSealedLetter.cost !== "undefined" ? productObj.customizedProducts.appliqueId.front.heatSealedLetter.cost : 0;
    const total_front_heatSealedLetter_price = totalQty * parseFloat(front_heatSealedLetter_price);
    /* applique front stitchingType */
    const front_stitchingType_price = productObj.customizedProducts.appliqueId.front.stitchingType && typeof productObj.customizedProducts.appliqueId.front.stitchingType.price !== "undefined" ? productObj.customizedProducts.appliqueId.front.stitchingType.price : 0;
    const total_front_stitchingType_price = totalQty * parseFloat(front_stitchingType_price);

    /* total applique price */
    totalPrice = totalPrice + (
      total_front_background_backgroundMaterial_price +
      total_front_background_backgroundColor_price +
      total_front_background_threadColor_price +
      total_front_foreground_foregroundMaterial_price +
      total_front_foreground_foregroundColor_price +
      total_front_foreground_threadColor_price +
      total_front_heatSealedLetter_price +
      total_front_stitchingType_price +
      total_back_background_backgroundMaterial_price +
      total_back_background_backgroundColor_price +
      total_back_background_threadColor_price +
      total_back_foreground_foregroundMaterial_price +
      total_back_foreground_foregroundColor_price +
      total_back_foreground_threadColor_price +
      total_back_heatSealedLetter_price +
      total_back_stitchingType_price
    );
    console.log("totalPrice after applique : ", totalPrice);
    /* embroidary */
    productObj.customizedProducts.embroidaryId.map(function (emdryItem) {
      let type = convertToSideValue(emdryItem);

      switch (type) {
        case "crest_and_images":
          for (const keyElement in emdryItem.keys) {
            let basePrice = emdryItem.basePrice.filter((itm) => itm.name == keyElement);
            /* total */
            totalPrice = totalPrice + (totalQty * parseFloat(emdryItem.keys[keyElement].imageSize.price) + basePrice[0].price);
          }
          break;
        case "chest":
        case "front":
        case "back":
        case "arm_right":
        case "arm_left":
        case "right_cuff":
        case "left_cuff":
        case "on_collar":
        case "collar_flipped_up":
        case "hood":
        case "hood_forward":
        case "hood_backward":    
          for (const keyElement in emdryItem.keys) {
            let basePrice = emdryItem.basePrice.filter((itm) => itm.name == keyElement);

            let textLine1 = emdryItem.keys[keyElement].textLine1.value.length;
            let textLine2 = emdryItem.keys[keyElement].textLine2 ? emdryItem.keys[keyElement].textLine2.value.length : 0;
            let textLine3 = emdryItem.keys[keyElement].textLine3 ? emdryItem.keys[keyElement].textLine3.value.length : 0;

            let fontSizeName = emdryItem.keys[keyElement].fontSize.name;

            let limitSize = parseInt(fontSizeName.substring(fontSizeName.indexOf("(") + 1, fontSizeName.indexOf("(") + 2));

            let actualApplicableSize = 0;
            switch (true) {
              case textLine1 > limitSize: actualApplicableSize += (textLine1 - limitSize);
              case textLine2 > limitSize: actualApplicableSize += (textLine2 - limitSize);
              case textLine3 > limitSize: actualApplicableSize += (textLine3 - limitSize);
            }

            /* total */
            totalPrice = totalPrice + (totalQty * basePrice[0].price);
            totalPrice = totalPrice + (totalQty * actualApplicableSize * parseFloat(emdryItem.keys[keyElement].fontSize.price));
            totalPrice = totalPrice + (totalQty * parseFloat(emdryItem.keys[keyElement].threadColor.price));
          }
          break;
      }
    });

    console.log("totalPrice = ", totalPrice);

    return priceFormat(totalPrice);
  }

  const calculateTotalAmount = (orderObj) => {
    let sub_total = calculateSubTotalAmount(orderObj);
    let discountVal = rowData.additionalData && typeof orderObj.additionalData.totalOrderDiscount !== "undefined" ? parseFloat(orderObj.additionalData.totalOrderDiscount) : 0.00;
    return priceFormat(parseFloat(sub_total) - discountVal);
  }

  const calculateNetAmount = (orderObj) => {
    let total = calculateTotalAmount(orderObj);
    console.log(orderObj);
    let salesTaxVal = rowData.additionalData && typeof orderObj.additionalData.totalOrderSalesTax !== "undefined" ? parseFloat(orderObj.additionalData.totalOrderSalesTax) : 0.00;
    let countryTaxVal = rowData.additionalData && typeof orderObj.additionalData.totalOrderCountryTax !== "undefined" ? parseFloat(orderObj.additionalData.totalOrderCountryTax) : 0.00;
    let rushFeeTaxVal = rowData.additionalData && typeof orderObj.additionalData.totalOrderRushFeeTax !== "undefined" ? parseFloat(orderObj.additionalData.totalOrderRushFeeTax) : 0.00;

    let greekLicensingFeeVal = rowData.additionalData && typeof orderObj.additionalData.totalOrderGreekLicensingFee !== "undefined" ? parseFloat(orderObj.additionalData.totalOrderGreekLicensingFee) : 0.00;
    let greekLicensingFeeVal2 = rowData.additionalData && typeof orderObj.additionalData.totalOrderGreekLicensingFee2 !== "undefined" ? parseFloat(orderObj.additionalData.totalOrderGreekLicensingFee2) : 0.00;
    let greekLicensingFeeVal3 = rowData.additionalData && typeof orderObj.additionalData.totalOrderGreekLicensingFee3 !== "undefined" ? parseFloat(orderObj.additionalData.totalOrderGreekLicensingFee3) : 0.00;
    let chargeVal = rowData.additionalData && typeof orderObj.additionalData.totalOrderAdditionalCharge !== "undefined" ? parseFloat(orderObj.additionalData.totalOrderAdditionalCharge) : 0.00;
    let shippingChargeVal = rowData.additionalData && typeof orderObj.additionalData.totalOrderShippingCharge !== "undefined" ? parseFloat(orderObj.additionalData.totalOrderShippingCharge) : 0.00;
    let signatureChargeVal = rowData.additionalData && typeof orderObj.additionalData.isPickupSignatureNeeded !== "undefined" && orderObj.additionalData.isPickupSignatureNeeded == true? (rowData.additionalData && typeof orderObj.additionalData.isPickupSignatureCharge !== "undefined"?parseFloat(orderObj.additionalData.isPickupSignatureCharge):2.00) : 0.00;
    let adjustmentPrice = rowData.additionalData && typeof orderObj.additionalData.totalOrderAdjustmentPrice !== "undefined" ? parseFloat(orderObj.additionalData.totalOrderAdjustmentPrice) : 0.00;
    console.log(`${total}+${salesTaxVal}+${countryTaxVal}+${rushFeeTaxVal}+${greekLicensingFeeVal}+${chargeVal}+${shippingChargeVal}+${signatureChargeVal}+${adjustmentPrice} = ${(total + salesTaxVal + countryTaxVal + rushFeeTaxVal + greekLicensingFeeVal + chargeVal + shippingChargeVal + signatureChargeVal + adjustmentPrice)}`)
    return priceFormat(parseFloat(total) + salesTaxVal + countryTaxVal + rushFeeTaxVal + greekLicensingFeeVal + greekLicensingFeeVal2 + greekLicensingFeeVal3 + chargeVal + shippingChargeVal + signatureChargeVal + adjustmentPrice);
  }

  const dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});
  }

  const getGeneratedIframePageURL = ({ html }) => {
    const getBlobURL = (code, type) => {
      const blob = new Blob([code], { type })
      return URL.createObjectURL(blob)
    }
  
    const source = `
      <html>
        ${html || ''}
      </html>
    `
  
    return getBlobURL(source, 'text/html')
  }

  useEffect(() => {
    console.log("======rowData==========");
    let intVal = 0;
    console.log("orderHTML : ", orderHTML);
    console.log("rowData : ", rowData);
    if(orderHTML != "" && rowData != null){ 
      /* generate iframe html url */
      /* const previewIframeHTMLUrl = getGeneratedIframePageURL({
        html: orderHTML
      }); */
      let intVal = setInterval(() => {
        console.log("checking for document.querySelector('#previewIframeEditOrder') : ", document.querySelector('#previewIframeEditOrder'));
        /* load iframe source */
        if(document.querySelector('#previewIframeEditOrder') != null){
          //alert("hi");
          let iframeOrderEditPreview = document.querySelector('#previewIframeEditOrder');
          //iframeOrderEditPreview.src = previewIframeHTMLUrl;
          //console.log("orderHTML :===", orderHTML);
          iframeOrderEditPreview.innerHTML = orderHTML;

          clearInterval(intVal);
        }
      }, 500);
      

      if(document.querySelector('#previewIframePrintOrder') != null){
        let iframeOrderPrint = document.querySelector('#previewIframePrintOrder');
        //iframeOrderPrint.src = previewIframeHTMLUrl;
        iframeOrderPrint.innerHTML = orderHTML;
      }
    }

    return () => {
      clearInterval(intVal);
    }
  }, [orderHTML, rowData]);

  const showOrderDetails = (rowData) => {
    console.log("rowData : ", rowData);
    return (
      <>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
          <Box ml={5} mb={2.5} style={{width:"100%"}}>
            <Grid container spacing={5} >
              {/* <Grid item xs={12}>
                <h3 style={{ color: "blue", textAlign: "center" }}>
                  <u>Preview Information</u>
                </h3>
              </Grid> */}
              <Grid item xs={12} >
                <FormControl style={{width:"98%", height:"1000px"}}>
                  <div id="previewIframeEditOrder" style={{width:"100%", height:"400%", overflowY:"scroll", padding:"5%"}}>

                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
          {/* <Box ml={5} mb={2.5}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <h3 style={{ "color": "blue" }}>
                  <u>User Information</u>
                </h3>
              </Grid>
              <Grid item >
                <FormControl>
                  {`Name : ${rowData.userDetails.name}`}
                  
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl>
                  {`Email Address : ${rowData.userDetails.email}`}
                  
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl>
                  {`Phone Number : ${rowData.userDetails.phone}`}
                  
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box ml={5} mb={2.5}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <h3 style={{ "color": "blue" }}>
                  <u>Order Information</u>
                </h3>
              </Grid>
              <Grid item >
                <FormControl>
                  {`OrderId : ${rowData.orderId}`}
                  
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl>
                  {`placedAt : ${formatDate(rowData.placedAt)}`}
                
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl>
                  {`Accepted/Cancelled : ${rowData.orderAccepted
                        ? "Accepted"
                        : rowData.orderCancelled
                          ? "Cancelled"
                          : ""}`}
                  
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl>
                  {`Payment Status : ${rowData.status}`}
                  
                </FormControl>
              </Grid>
              {rowData.additionalData && rowData.additionalData.discountCode && <Grid item >
                <FormControl>
                  {`Discount Code : ${rowData.additionalData.discountCode}`}
                  
                </FormControl>
              </Grid>}
              {rowData.additionalData && rowData.additionalData.isPickupSignatureNeeded && <Grid item >
                <FormControl>
                  {`Signature Confirmation Needed : ${rowData.additionalData.isPickupSignatureNeeded ? "Yes" : "No"}`}
                  
                </FormControl>
              </Grid>}
              {rowData.additionalData && rowData.additionalData.needByDate && <Grid item >
                <FormControl>
                  {`Need By Date : ${formatDate(rowData.additionalData.needByDate)}`}
                
                </FormControl>
              </Grid>}
              {rowData.additionalData && rowData.additionalData.referrel && <Grid item >
                <FormControl>
                  {`Referrel : ${rowData.additionalData.referrel}`}
                  
                </FormControl>
              </Grid>}
              {rowData.additionalData && rowData.additionalData.shippingType && <Grid item >
                <FormControl>
                  {`Initial Shipping Type : ${rowData.additionalData.shippingType}`}
                  
                </FormControl>
              </Grid>}
              
              {rowData.additionalData && rowData.additionalData.textMeAfterQuoteSent && <Grid item >
                <FormControl>
                  {`Text Me After Quote Sent : ${rowData.additionalData.textMeAfterQuoteSent ? "ACTIVE" : "INACTIVE"}`}
                
                </FormControl>
              </Grid>}

            </Grid>
          </Box>*/}
          <Box ml={15} mb={2.5} mt={2.5}>

            <Grid container spacing={5}>
              <Grid item >
                <FormControl>

                  <InputLabel id="billing-address-label">Billing Address</InputLabel>
                  <Select
                    labelId="billing-address-label"
                    id="billing-address"
                    value={billingAddress}
                    label="Age"
                    onChange={(e) => {
                      let val = e.target.value.trim();

                      let orderObj = {
                        ...rowData,
                        billingAddressId: val
                      };

                      // set RowData 
                      setRowData(orderObj);
                    }}
                    style={{ minWidth: "700px" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {
                      userAddresses.map((addr) => <MenuItem value={addr._id}>{`${addr.addressLine1} ${addr.addressLine2} ${addr.landmark}, ${addr.city}, ${addr.state} ${addr.pinCode}`}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl>
                  
                  <InputLabel id="shipping-address-label">Shipping Address</InputLabel>
                  <Select
                    labelId="shipping-address-label"
                    id="shipping-address"
                    value={shippingAddress}
                    label="Age"
                    onChange={(e) => {
                      let val = e.target.value.trim();

                      let orderObj = {
                        ...rowData,
                        shippingAddressId: val
                      };

                      // set RowData 
                      setRowData(orderObj);
                    }}
                    style={{ minWidth: "700px" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {
                      userAddresses.map((addr) => <MenuItem value={addr._id}>{`${addr.addressLine1} ${addr.addressLine2} ${addr.landmark}, ${addr.city}, ${addr.state} ${addr.pinCode}`}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>

            </Grid>
          </Box>

          <Box ml={5} mb={2.5}  style={{width:"100%"}}>
            {/*<Grid item xs={12}>
              <h3 style={{ "color": "blue" }}>
                <u>Product List</u>
              </h3>
            </Grid>

            {rowData.productList.map((product, index) => {
              console.log(product);
              

              return (
                <>
                  <Box ml={0} mb={2.5}>
                    {product.productId.name == null ? (
                      <h3 style={{ "color": "blue" }}>
                        <u>Product Id : {product.productId}</u>
                      </h3>
                    ) : (
                      <>
                        <h3 style={{ "color": "blue" }}>
                          <u>Product Id : {product.productId._id}</u>
                        </h3>
                        <Box ml={5} mb={2.5}>
                          <Grid container spacing={5}>
                            <Grid item>
                              <FormControl>
                                
                                {`Name : `}{wordWrap(product.productId.name, 15)}
                                
                              </FormControl>
                            </Grid>
                            <Grid item >
                              <FormControl>
                                {`Description : `}{wordWrap(product.productId.description, 25)}
                                
                              </FormControl>
                            </Grid>
                            <Grid item >
                              <FormControl>
                                {`Item : `}{wordWrap(product.productId.item, 15)}
                                
                              </FormControl>
                            </Grid>
                            <Grid item >
                              <FormControl>
                                {`Includes : `}{wordWrap(product.productId.includes, 15)}
                                
                              </FormControl>
                            </Grid>
                            <Grid item >
                              <FormControl>
                                {`Clearance : ${product.productId.clearance == true?"Yes":"No"}`}
                                
                              </FormControl>
                            </Grid>

                            <Grid item >
                              <FormControl>
                                {`Gender : ${product.productId.gender}`}
                                
                              </FormControl>
                            </Grid>


                          </Grid>
                        </Box>
                        <Box ml={10} >
                          <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>
                            <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                            <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                            <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Base Price: </Grid>
                            <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                              <FormControl>
                                {`${product.productId.currency} ${priceFormat(product.productId.price)}`}
                                
                              </FormControl>
                            </Grid>
                            
                          </Grid>
                        </Box>
                        
                      </>
                    )}
                    <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                    <Box ml={5} mb={2.5}>
                      <h4 style={{ "color": "blue" }} >
                        <u>Color</u>
                      </h4>
                      <Box ml={5} mb={2.5}>
                        <Grid container spacing={5}>
                          {product.customizedProducts.colors && (<><Grid item >
                            <FormControl>
                              {`Color Code : ${product.customizedProducts.colors.colorCode}`}
                              <span
                                style={{ backgroundColor: product.customizedProducts.colors.colorCode, width:"24px", height:"24px" }}
                              ></span>
                            </FormControl>
                          </Grid>

                            <Grid item >
                              <FormControl>
                                {`Color Name : ${product.customizedProducts.colors.colorName}`}
                                
                              </FormControl>
                            </Grid></>)}
                        </Grid>
                      </Box>

                    </Box>
                    <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                    <Box ml={5} mb={2.5}>
                      <h4 style={{ "color": "blue" }} >
                        <u>Size&Quantity</u>
                      </h4>
                      <Box ml={5} mb={2.5}>
                        {product.customizedProducts.colors['size&quantity'].map((sizeInfo, sizeInfoIndex) => (<>
                          <h4 style={{ "color": "blue" }} >
                            <u>{sizeInfo.sizeName}</u>
                          </h4>
                          <Box ml={5} mb={2.5} >
                            <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>
                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                <FormControl>
                                  <TextField
                                    fullWidth
                                    type="number"
                                    min="1"
                                    label="Qty"
                                    placeholder=""
                                    disabled={false}
                                    value={sizeInfo.quantity}
                                    onChange={(e) => {
                                      let qty = e.target.value.trim();
                                      qty = qty < 1 ? 1 : qty;
                                      console.log("qty : ", qty);

                                      let productObj = {
                                        ...product,
                                        customizedProducts: {
                                          ...rowData.productList[index].customizedProducts,
                                          colors: {
                                            ...rowData.productList[index].customizedProducts.colors,
                                            ["size&quantity"]: rowData.productList[index].customizedProducts.colors["size&quantity"].map((sizeQtyRow, sizeQtyIndex) => {
                                              if (sizeQtyIndex == sizeInfoIndex) {
                                                return {
                                                  ...rowData.productList[index].customizedProducts.colors["size&quantity"][sizeInfoIndex],
                                                  quantity: qty != "" ? qty : 0
                                                }
                                              } else {
                                                return sizeQtyRow
                                              }
                                            })
                                          }
                                        }
                                      };

                                      let totalQty = totalQtyCalculation(productObj);
                                      let totalPrice = totalPriceCalculation(productObj);

                                      // set RowData 
                                      setRowData({
                                        ...rowData,
                                        productList: rowData.productList.map((prdct) => {
                                          if (prdct.productId == product.productId) {
                                            return {
                                              ...product,
                                              totalQuantity: totalQty,
                                              totalPrice: totalPrice,
                                              customizedProducts: {
                                                ...rowData.productList[index].customizedProducts,
                                                colors: {
                                                  ...rowData.productList[index].customizedProducts.colors,
                                                  ["size&quantity"]: rowData.productList[index].customizedProducts.colors["size&quantity"].map((sizeQtyRow, sizeQtyIndex) => {
                                                    if (sizeQtyIndex == sizeInfoIndex) {
                                                      return {
                                                        ...rowData.productList[index].customizedProducts.colors["size&quantity"][sizeInfoIndex],
                                                        quantity: qty
                                                      }
                                                    } else {
                                                      return sizeQtyRow
                                                    }
                                                  })
                                                }
                                              }
                                            }
                                          } else {
                                            return product;
                                          }
                                        })
                                      });
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                <FormControl>
                                  {`${product.currency} ${priceFormat(sizeInfo.sizePrice)}`}
                                  
                                </FormControl>
                              </Grid>

                              <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                <FormControl>
                                  {`${product.totalQuantity} (Qty) X ${product.currency} ${priceFormat(sizeInfo.sizePrice)} = ${product.currency} ${priceFormat(sizeInfo.quantity * sizeInfo.sizePrice)}`}
                                  
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Box>
                        </>)
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                  <Box ml={5} mb={2.5}>
                    
                    <Box ml={0} mb={2.5}>
                      <h4 style={{ "color": "blue" }} >
                        <u>Applique</u>
                      </h4>

                      <Box ml={5} mb={2.5}>
                        <h4 style={{ "color": "blue" }} >
                          <u>Front</u>
                        </h4>
                        <Box ml={5} mb={2.5}>
                          <h4 style={{ "color": "blue" }} >
                            <u>Text & Position Info</u>
                          </h4>
                          <Box ml={5} mb={2.5}>
                            <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>

                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 5' }}>
                                <FormControl>
                                  {`Position : ${product.customizedProducts.appliqueId.front.letterPlacement?product.customizedProducts.appliqueId.front.letterPlacement:""}`}
                                  
                                </FormControl>
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 5' }}>
                                <FormControl>
                                  {`Letter Size : ${product.customizedProducts.appliqueId.front.letterSize.size}`}
                                  
                                </FormControl>
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '3 / 5' }}>
                                <FormControl>
                                  {`Organization Text : `}<b>{`${product.customizedProducts.appliqueId.front.organization}`}</b>
                                  
                                </FormControl>
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '4 / 5' }}>
                                <FormControl>
                                  {`Special Instruction : ${product.customizedProducts.appliqueId.front.specialInstruction}`}
                                  
                                </FormControl>
                              </Grid>

                            </Grid>
                          </Box>
                        </Box>
                        {product.customizedProducts.appliqueId.front
                          .foreground.foregroundColor && typeof product.customizedProducts.appliqueId.front
                            .foreground.foregroundColor.name !== "undefined" && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                        {product.customizedProducts.appliqueId.front
                          .foreground.foregroundColor && typeof product.customizedProducts.appliqueId.front
                            .foreground.foregroundColor.name !== "undefined" && <Box ml={5} mb={2.5}>
                            <h4 style={{ "color": "blue" }} >
                              <u>Forground</u>
                            </h4>
                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>

                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Material Name : `}<br />{`${product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.name}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                  
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.price)} = ${product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.price)}`}
                                    
                                  </FormControl>
                                </Grid>

                              </Grid>

                            </Box>
                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>
                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Material Color : `}<br/>{`${product.customizedProducts.appliqueId.front.foreground.foregroundColor.name}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                  <FormControl>
                                    
                                    {product.customizedProducts.appliqueId.back
                                      .foreground.foregroundColor
                                      .value && product.customizedProducts.appliqueId.back
                                        .foreground.foregroundColor
                                        .value.indexOf("http") !== -1 ? <><InputLabel style={{ position: "absolute", "top": "-50%" }}>Preview</InputLabel><img src={product.customizedProducts.appliqueId.back
                                          .foreground.foregroundColor
                                          .value} alt={product.customizedProducts.appliqueId.back
                                            .foreground.foregroundColor
                                            .value} /></> : <>
                                            {`Thread Color Code : ${product.customizedProducts.appliqueId.back.foreground.foregroundColor.value}`}
                                            <span
                                              style={{ backgroundColor: product.customizedProducts.appliqueId.back.foreground.foregroundColor.value, width:"24px", height:"24px" }}
                                            ></span>
                                            </>}
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.customizedProducts.appliqueId.front.foreground.foregroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.foregroundColor.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.foreground.foregroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.foregroundColor.price)} = ${product.customizedProducts.appliqueId.front.foreground.foregroundColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.foreground.foregroundColor.price)}`}
                                    
                                  </FormControl>
                                </Grid>

                              </Grid>
                            </Box>
                            <Box ml={5} mb={2.5}>
                              {product.customizedProducts.appliqueId.front.foreground
                                .threadColor && product.customizedProducts.appliqueId.front
                                  .foreground.threadColor.active == true && (<Grid container spacing={5} sx={{
                                    display: 'grid',
                                    gridAutoColumns: '1fr',
                                    gap: 1,
                                  }}>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                      <FormControl>
                                        {`Thread Name : `}<br/>{`${product.customizedProducts.appliqueId.front.foreground.threadColor.name}`}
                                        
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                      <FormControl>
                                        {`Thread Color Code : ${product.customizedProducts.appliqueId.front.foreground.threadColor.value}`}
                                        <span
                                          style={{ backgroundColor: product.customizedProducts.appliqueId.front.foreground.threadColor.value, width:"24px", height:"24px" }}
                                        ></span>
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                      <FormControl>
                                        {`${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.threadColor.price)}`}
                                        
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                      <FormControl>
                                        {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.threadColor.price)} = ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.foreground.threadColor.price)}`}
                                        
                                      </FormControl>
                                    </Grid>

                                  </Grid>)}
                            </Box>
                        </Box>}
                        {product.customizedProducts.appliqueId.front
                          .background.backgroundColor && typeof product.customizedProducts.appliqueId.front
                            .background.backgroundColor.name !== "undefined" && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                        {product.customizedProducts.appliqueId.front
                          .background.backgroundColor && typeof product.customizedProducts.appliqueId.front
                            .background.backgroundColor.name !== "undefined" && <Box ml={5} mb={2.5}>
                            <h4 style={{ "color": "blue" }} >
                              <u>Background</u>
                            </h4>

                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>

                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Material Name : ${product.customizedProducts.appliqueId.front.background.backgroundMaterial.name}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                  
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.customizedProducts.appliqueId.front.background.backgroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.front.background.backgroundMaterial.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.background.backgroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.front.background.backgroundMaterial.price)} = ${product.customizedProducts.appliqueId.front.background.backgroundMaterial.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.background.backgroundMaterial.price)}`}
                                    
                                  </FormControl>
                                </Grid>

                              </Grid>

                            </Box>
                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>
                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Material Color Name : `}<br/>{`${product.customizedProducts.appliqueId.front.background.backgroundColor.name}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                  <FormControl>
                                    {product.customizedProducts.appliqueId.back
                                      .background.backgroundColor
                                      .value && product.customizedProducts.appliqueId.back
                                      .background.backgroundColor
                                      .value.indexOf("http") !== -1 ? <><InputLabel style={{ position: "absolute", "top": "-50%" }}>Preview</InputLabel><img src={product.customizedProducts.appliqueId.back
                                        .background.backgroundColor
                                        .value} alt={product.customizedProducts.appliqueId.back
                                          .background.backgroundColor
                                          .value} /></> : <>
                                          {`Thread Color Code : ${product.customizedProducts.appliqueId.back.background.backgroundColor.value}`}
                                          <span
                                            style={{ backgroundColor: product.customizedProducts.appliqueId.back.background.backgroundColor.value, width:"24px", height:"24px" }}
                                          ></span>
                                          </>}
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.customizedProducts.appliqueId.front.background.backgroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.background.backgroundColor.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.background.backgroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.background.backgroundColor.price)} = ${product.customizedProducts.appliqueId.front.background.backgroundColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.background.backgroundColor.price)}`}
                                    
                                  </FormControl>
                                </Grid>

                              </Grid>
                            </Box>
                            <Box ml={5} mb={2.5}>
                              {product.customizedProducts.appliqueId.front.foreground
                                .threadColor && product.customizedProducts.appliqueId.front
                                  .foreground.threadColor.active == true && (<Grid container spacing={5} sx={{
                                    display: 'grid',
                                    gridAutoColumns: '1fr',
                                    gap: 1,
                                  }}>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                      <FormControl>
                                        {`Thread Name : `}<br/>{`${product.customizedProducts.appliqueId.front.foreground.threadColor.name}`}
                                        
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                      <FormControl>
                                        {`Thread Color Code : ${product.customizedProducts.appliqueId.front.foreground.threadColor.value}`}
                                        <span
                                          style={{ backgroundColor: product.customizedProducts.appliqueId.front.foreground.threadColor.value, width:"24px", height:"24px" }}
                                        ></span>
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                      <FormControl>
                                        {`${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.threadColor.price)}`}
                                        
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                      <FormControl>
                                        {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.threadColor.price)} = ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.foreground.threadColor.price)}`}
                                        
                                      </FormControl>
                                    </Grid>

                                  </Grid>)}
                            </Box>
                        </Box>}
                        {product.customizedProducts.appliqueId.front
                          .heatSealedLetter.isactive == true && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                        {product.customizedProducts.appliqueId.front
                          .heatSealedLetter.isactive == true && (<Box ml={5} mb={2.5}>
                            <h4 style={{ "color": "blue" }} >
                              <u>Heat Sealed Letter</u>
                            </h4>
                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>
                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  {`Is Checked : ${product.customizedProducts.appliqueId.front.heatSealedLetter.isactive == true ? "Yes" : "No"}`}
                                  
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.currency} ${priceFormat("2.00")}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.currency} ${priceFormat("2.00")} = ${product.currency} ${priceFormat(product.totalQuantity * 2.00)}`}
                                    
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>)}
                        {product.customizedProducts.appliqueId.front
                          .stitchingType && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                        {product.customizedProducts.appliqueId.front
                          .stitchingType && (<Box ml={5} mb={2.5}>
                            <h4 style={{ "color": "blue" }} >
                              <u>Stitching</u>
                            </h4>
                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>
                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Type : ${product.customizedProducts.appliqueId.front.stitchingType.stitchingType}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.customizedProducts.appliqueId.front.stitchingType.currency} ${priceFormat(product.customizedProducts.appliqueId.front.stitchingType.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.stitchingType.currency} ${priceFormat(product.customizedProducts.appliqueId.front.stitchingType.price)} = ${product.customizedProducts.appliqueId.front.stitchingType.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.stitchingType.price)}`}
                                  
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>)}
                        {product.customizedProducts.appliqueId.front.customFiles &&
                          product.customizedProducts.appliqueId.front.customFiles
                            .length > 0 && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}

                        {product.customizedProducts.appliqueId.front.customFiles &&
                          product.customizedProducts.appliqueId.front.customFiles
                            .length > 0 && (
                            <Box ml={5} mb={2.5}>
                              <h4 style={{ "color": "blue" }} >
                                <u>Preview</u>
                              </h4>

                              <Grid container spacing={5}>
                                {product.customizedProducts.appliqueId.front.customFiles.map(
                                  (file) => (
                                    <Grid item >
                                      <FormControl>
                                        <img
                                          src={file.content}
                                          alt={file.name}
                                          width="200px"
                                        />
                                      </FormControl>
                                    </Grid>
                                  )
                                )}
                              </Grid>
                            </Box>
                          )}
                      </Box>
                      <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                      <Box ml={5} mb={2.5}>
                        <h4 style={{ "color": "blue" }} >
                          <u>Back</u>
                        </h4>
                        <Box ml={5} mb={2.5}>
                          <h4 style={{ "color": "blue" }} >
                            <u>Text & Position Info</u>
                          </h4>
                          <Box ml={5} mb={2.5}>
                          <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>

                            <Grid item sx={{ gridRow: '1', gridColumn: '1 / 5' }}>
                              <FormControl>
                                {`Position : ${product.customizedProducts.appliqueId.back.letterPlacement?product.customizedProducts.appliqueId.back.letterPlacement:""}`}
                                
                              </FormControl>
                            </Grid>
                            <Grid item  sx={{ gridRow: '1', gridColumn: '2 / 5' }}>
                              <FormControl>
                                {`Letter Size : ${product.customizedProducts.appliqueId.back.letterSize.size}`}
                                
                              </FormControl>
                            </Grid>
                            <Grid item  sx={{ gridRow: '1', gridColumn: '3 / 5' }}>
                              <FormControl>
                                {`Organization Text : `}<b>{`${product.customizedProducts.appliqueId.back.organization}`}</b>
                                
                              </FormControl>
                            </Grid>
                            <Grid item  sx={{ gridRow: '1', gridColumn: '4 / 5' }}>
                              <FormControl>
                                {`Special Instruction : ${product.customizedProducts.appliqueId.back.specialInstruction}`}
                                
                              </FormControl>
                            </Grid>

                          </Grid>
                          </Box>
                        </Box>
                        {product.customizedProducts.appliqueId.back
                          .foreground.foregroundColor && typeof product.customizedProducts.appliqueId.back
                            .foreground.foregroundColor.name !== "undefined" && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                        {product.customizedProducts.appliqueId.back
                          .foreground.foregroundColor && typeof product.customizedProducts.appliqueId.back
                            .foreground.foregroundColor.name !== "undefined" && <Box ml={5} mb={2.5}>
                            <h4 style={{ "color": "blue" }} >
                              <u>Forground</u>
                            </h4>
                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>

                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Material Name : ${product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.name}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                  
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.price)} = ${product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.price)}`}
                                    
                                  </FormControl>
                                </Grid>

                              </Grid>

                            </Box>
                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>
                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Material Color Name : `}<br/>{`${product.customizedProducts.appliqueId.back.foreground.foregroundColor.name}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                  <FormControl>
                                    {product.customizedProducts.appliqueId.back
                                      .foreground.foregroundColor
                                      .value.indexOf("http") !== -1 ? <><InputLabel style={{ position: "absolute", "top": "-50%" }}>Preview</InputLabel><img src={product.customizedProducts.appliqueId.back
                                        .foreground.foregroundColor
                                        .value} alt={product.customizedProducts.appliqueId.back
                                          .foreground.foregroundColor
                                          .value} /></> : <>
                                          {`Material Color Code : ${product.customizedProducts.appliqueId.back.foreground.foregroundColor.value}`}
                                          <span
                                          style={{ backgroundColor: product.customizedProducts.appliqueId.back.foreground.foregroundColor.value, width:"24px", height:"24px" }}
                                        ></span>
                                        </>}
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.customizedProducts.appliqueId.back.foreground.foregroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.foregroundColor.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.foreground.foregroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.foregroundColor.price)} = ${product.customizedProducts.appliqueId.back.foreground.foregroundColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.foreground.foregroundColor.price)}`}
                                    
                                  </FormControl>
                                </Grid>

                              </Grid>
                            </Box>
                            <Box ml={5} mb={2.5}>
                              {product.customizedProducts.appliqueId.back.foreground
                                .threadColor && product.customizedProducts.appliqueId.back
                                  .foreground.threadColor.active == true && (<Grid container spacing={5} sx={{
                                    display: 'grid',
                                    gridAutoColumns: '1fr',
                                    gap: 1,
                                  }}>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                      <FormControl>
                                        {`Thread Name : `}<br/>{`${product.customizedProducts.appliqueId.back.foreground.threadColor.name}`}
                                        
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                      <FormControl>
                                        {`Thread Color Code : ${product.customizedProducts.appliqueId.back.foreground.threadColor.value}`}
                                        <span
                                          style={{ backgroundColor: product.customizedProducts.appliqueId.back.foreground.threadColor.value, width:"24px", height:"24px" }}
                                        ></span>
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                      <FormControl>
                                        {`${product.customizedProducts.appliqueId.back.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.threadColor.price)}`}
                                        
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                      <FormControl>
                                        {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.threadColor.price)} = ${product.customizedProducts.appliqueId.back.foreground.threadColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.foreground.threadColor.price)}`}
                                        
                                      </FormControl>
                                    </Grid>

                                  </Grid>)}
                            </Box>
                        </Box>}
                        {product.customizedProducts.appliqueId.back
                          .background.backgroundColor && typeof product.customizedProducts.appliqueId.back
                            .background.backgroundColor.name !== "undefined" && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                        {product.customizedProducts.appliqueId.back
                          .background.backgroundColor && typeof product.customizedProducts.appliqueId.back
                            .background.backgroundColor.name !== "undefined" && <Box ml={5} mb={2.5}>
                            <h4 style={{ "color": "blue" }} >
                              <u>Background</u>
                            </h4>

                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>

                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Material Name : ${product.customizedProducts.appliqueId.back.background.backgroundMaterial.name}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                  
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.customizedProducts.appliqueId.back.background.backgroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.backgroundMaterial.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.background.backgroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.backgroundMaterial.price)} = ${product.customizedProducts.appliqueId.back.background.backgroundMaterial.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.background.backgroundMaterial.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                              </Grid>

                            </Box>
                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>
                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Material Color Name : `}<br/>{`${product.customizedProducts.appliqueId.back.background.backgroundColor.name}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                  <FormControl>
                                    
                                    {product.customizedProducts.appliqueId.back
                                      .background.backgroundColor
                                      .value && product.customizedProducts.appliqueId.back
                                      .background.backgroundColor
                                      .value.indexOf("http") !== -1 ? <><InputLabel style={{ position: "absolute", "top": "-50%" }}>Preview</InputLabel><img src={product.customizedProducts.appliqueId.back
                                        .background.backgroundColor
                                        .value} alt={product.customizedProducts.appliqueId.back
                                          .background.backgroundColor
                                          .value} /></> : <>
                                          {`Background Color Code : ${product.customizedProducts.appliqueId.back.background.backgroundColor.value}`}
                                          </>}
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.customizedProducts.appliqueId.back.background.backgroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.backgroundColor.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.background.backgroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.backgroundColor.price)} = ${product.customizedProducts.appliqueId.back.background.backgroundColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.background.backgroundColor.price)}`}
                                    
                                  </FormControl>
                                </Grid>

                              </Grid>
                            </Box>
                            <Box ml={5} mb={2.5}>
                              {product.customizedProducts.appliqueId.back.background
                                .threadColor && product.customizedProducts.appliqueId.back
                                  .background.threadColor.active == true && (<Grid container spacing={5} sx={{
                                    display: 'grid',
                                    gridAutoColumns: '1fr',
                                    gap: 1,
                                  }}>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                      <FormControl>
                                        {`Thread Name : `}<br/>{`${product.customizedProducts.appliqueId.back.background.threadColor.name}`}
                                        
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                      <FormControl>
                                        {`Thread Color Code : ${product.customizedProducts.appliqueId.back.background.threadColor.value}`}
                                        <span
                                          style={{ backgroundColor: product.customizedProducts.appliqueId.back.background.threadColor.value, width:"24px", height:"24px" }}
                                        ></span>
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                      <FormControl>
                                        {`${product.customizedProducts.appliqueId.back.background.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.threadColor.price)}`}
                                        
                                      </FormControl>
                                    </Grid>
                                    <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                      <FormControl>
                                        {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.background.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.threadColor.price)} = ${product.customizedProducts.appliqueId.back.background.threadColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.background.threadColor.price)}`}
                                        
                                      </FormControl>
                                    </Grid>

                                  </Grid>)}
                            </Box>
                        </Box>}
                        {product.customizedProducts.appliqueId.back
                          .heatSealedLetter.isactive == true && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                        {product.customizedProducts.appliqueId.back
                          .heatSealedLetter.isactive == true && (<Box ml={5} mb={2.5}>
                            <h4 style={{ "color": "blue" }} >
                              <u>Heat Sealed Letter</u>
                            </h4>
                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>
                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Is Checked : ${product.customizedProducts.appliqueId.back.heatSealedLetter.isactive == true ? "Yes" : "No"}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.currency} ${priceFormat("2.00")}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.currency} ${priceFormat("2.00")} = ${product.currency} ${priceFormat(product.totalQuantity * 2.00)}`}
                                    
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>)}
                        {product.customizedProducts.appliqueId.back
                          .stitchingType && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                        {product.customizedProducts.appliqueId.back
                          .stitchingType && (<Box ml={5} mb={2.5}>
                            <h4 style={{ "color": "blue" }} >
                              <u>Stitching</u>
                            </h4>
                            <Box ml={5} mb={2.5}>
                              <Grid container spacing={5} sx={{
                                display: 'grid',
                                gridAutoColumns: '1fr',
                                gap: 1,
                              }}>
                                <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <FormControl>
                                    {`Type : ${product.customizedProducts.appliqueId.back.stitchingType.stitchingType}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                  <FormControl>
                                    {`${product.customizedProducts.appliqueId.back.stitchingType.currency} ${priceFormat(product.customizedProducts.appliqueId.back.stitchingType.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                                <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                  <FormControl>
                                    {`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.stitchingType.currency} ${priceFormat(product.customizedProducts.appliqueId.back.stitchingType.price)} = ${product.customizedProducts.appliqueId.back.stitchingType.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.stitchingType.price)}`}
                                    
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>)}
                        
                        {product.customizedProducts.appliqueId.back.customFiles &&
                          product.customizedProducts.appliqueId.back.customFiles
                            .length > 0 && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                        {product.customizedProducts.appliqueId.back.customFiles &&
                          product.customizedProducts.appliqueId.back.customFiles
                            .length > 0 && (
                          <>
                          <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                            <Box ml={5} mb={2.5}>
                              <h4 style={{ "color": "blue" }} >
                                <u>Preview</u>
                              </h4>

                              <Grid container spacing={5}>
                                {product.customizedProducts.appliqueId.back.customFiles.map(
                                  (file) => (
                                    <Grid item >
                                      <FormControl>
                                        <img
                                          src={file.content}
                                          alt={file.name}
                                          width="200px"
                                        />
                                      </FormControl>
                                    </Grid>
                                  )
                                )}
                              </Grid>
                            </Box>
                          </>
                          )}
                      </Box>
                    </Box>
                    <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                    <Box ml={0} mb={2.5}>
                      <h4 style={{ "color": "blue" }} >
                        <u>Embroidary</u>
                      </h4>
                      <Box ml={5} mb={2.5}>
                        {
                          product.customizedProducts.embroidaryId.map(function (emdryItem) {
                            let type = convertToSideValue(emdryItem);

                            switch (type) {
                              case "crest_and_images":
                                for (const keyElement in emdryItem.keys) {
                                  let basePrice = emdryItem.basePrice.filter((itm) => itm.name == keyElement);
                                  return <><Box ml={5} mb={2.5}>
                                    <h4 style={{ "color": "blue" }} >
                                      <u>{pascalCase(type.replace(/_/g, " "))}</u>
                                    </h4>
                                    <Box ml={5} mb={2.5}>
                                      {(Object.hasOwnProperty.call(emdryItem.keys, keyElement)) && <>
                                        <Grid container spacing={5} sx={{
                                          display: 'grid',
                                          gridAutoColumns: '1fr',
                                          gap: 1,
                                        }}>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                            <FormControl>
                                              {`Image Size : ${emdryItem.keys[keyElement].imageSize.name}`}
                                              
                                            </FormControl>
                                          </Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                            <FormControl>
                                              {`Location : ${emdryItem.keys[keyElement].embroidaryLocation.name}`}
                                              
                                              <img src={emdryItem.keys[keyElement].embroidaryLocation.image} alt={`Preview`} width={`25%`} />
                                            </FormControl>
                                          </Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                            <FormControl>
                                              {`${emdryItem.keys[keyElement].imageSize.currency} ${priceFormat(emdryItem.keys[keyElement].imageSize.price)}`}
                                              
                                            </FormControl>
                                          </Grid>

                                          <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                            <FormControl>
                                              {`${product.totalQuantity} (Qty) X ${emdryItem.keys[keyElement].imageSize.currency} ${emdryItem.keys[keyElement].imageSize.price} = ${emdryItem.keys[keyElement].imageSize.currency} ${priceFormat(product.totalQuantity * emdryItem.keys[keyElement].imageSize.price)}`}
                                              
                                            </FormControl>
                                          </Grid>


                                        </Grid>
                                      </>}
                                    </Box>
                                    <Box ml={5} mb={2.5}>
                                      <Grid container spacing={5} sx={{
                                        display: 'grid',
                                        gridAutoColumns: '1fr',
                                        gap: 1,
                                      }}>

                                        <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                          <FormControl>
                                            {`Position : ${basePrice[0].name?basePrice[0].name:""}`}
                                            
                                          </FormControl>
                                        </Grid>
                                        <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                                        <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                          <FormControl>
                                            {`${emdryItem.keys[keyElement].imageSize.currency} ${priceFormat(basePrice[0].price)}`}
                                            
                                          </FormControl>
                                        </Grid>

                                        <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                          <FormControl>
                                            {`${product.totalQuantity} (Qty) X ${emdryItem.keys[keyElement].imageSize.currency} ${basePrice[0].price} = ${emdryItem.keys[keyElement].imageSize.currency} ${priceFormat(product.totalQuantity * basePrice[0].price)}`}
                                            
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    
                                  </Box>
                                    <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                                  </>
                                }
                                break;
                              case "chest":
                              case "front":
                              case "back":
                              case "arm_left":
                              case "arm_right":
                              case "left_cuff":
                              case "right_cuff":
                              case "on_collar":
                              case "collar_flipped_up":
                              case "hood":
                              case "hood_forward":
                              case "hood_backward":   
                                for (const keyElement in emdryItem.keys) {
                                  let basePrice = emdryItem.basePrice.filter((itm) => itm.name == keyElement);
                                  console.log(emdryItem.keys[keyElement]);
                                  
                                  
                                  // let textLine1 = emdryItem.keys[keyElement].textLine1.value.length;
                                  // let textLine2 = emdryItem.keys[keyElement].textLine2 ? emdryItem.keys[keyElement].textLine2.value.length : 0;
                                  // let textLine3 = emdryItem.keys[keyElement].textLine3 ? emdryItem.keys[keyElement].textLine3.value.length : 0; 

                                  let fontSizeName = emdryItem.keys[keyElement].fontSize.name;

                                  let limitSize = parseInt(fontSizeName.substring(fontSizeName.indexOf("(") + 1, fontSizeName.indexOf("(") + 2));

                                  
                                  // console.log("(textLine1-limitSize) : " + actualTextLine1);
                                  // console.log("(textLine2-limitSize) : " + actualTextLine2);
                                  // console.log("(textLine3-limitSize) : " + actualTextLine3); 

                                  let textLines = [];
                                  let textLineLength = 0;
                                  let actualTextLine = 0;
                                  for(let itm in emdryItem.keys[keyElement]){
                                    if(itm.includes("text") && emdryItem.keys[keyElement][itm].value != ""){
                                      textLineLength = emdryItem.keys[keyElement][itm] ? emdryItem.keys[keyElement][itm].value.length : 0;
                                      actualTextLine = textLineLength > limitSize?(textLineLength - limitSize):textLineLength;
                                      actualTextLine = actualTextLine > 0?actualTextLine:0;
                                      textLines.push({
                                        value: emdryItem.keys[keyElement][itm].value,
                                        fontSize: emdryItem.keys[keyElement].fontSize.name,
                                        currency: emdryItem.keys[keyElement].fontSize.currency,
                                        price: emdryItem.keys[keyElement].fontSize.price,
                                        actualTextLine: actualTextLine
                                      });
                                    }
                                  }
                                  console.log("textLines : ", textLines);

                                  return <><Box ml={5} mb={2.5}>
                                    <h4 style={{ "color": "blue" }} >
                                      <u>{pascalCase(type.replace(/_/g, " "))}</u>
                                    </h4>
                                    {(Object.hasOwnProperty.call(emdryItem.keys, keyElement)) && <>

                                      {
                                        textLines.map((textObj, idx) => (
                                          <Box ml={5} mb={2.5}>
                                            <Grid container spacing={5} sx={{
                                              display: 'grid',
                                              gridAutoColumns: '1fr',
                                              gap: 1,
                                            }}>
                                              
                                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                                <FormControl>
                                                  {`Text Line ${idx+1} : ${textObj.value}`}
                                                </FormControl>
                                              </Grid>
                                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                                <FormControl>
                                                  {`${textObj.fontSize}`}
                                                </FormControl>
                                              </Grid>
                                              <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                                <FormControl>
                                                  {`${textObj.currency} ${priceFormat(textObj.price)} X ${textObj.actualTextLine}`}
                                                  
                                                </FormControl>
                                              </Grid>
                                              <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                                <FormControl>
                                                  {` ${product.totalQuantity} (Qty) X ${textObj.currency} ${priceFormat(textObj.actualTextLine * parseFloat(textObj.price))} = ${textObj.currency} ${priceFormat(product.totalQuantity * textObj.actualTextLine * parseFloat(textObj.price))}`}
                                                  
                                                </FormControl>
                                              </Grid>

                                            </Grid>
                                          </Box>
                                        ))
                                      }
                                      
                                      {emdryItem.keys[keyElement].threadColor.active == true && <Box ml={5} mb={2.5}>
                                        <Grid container spacing={5} sx={{
                                          display: 'grid',
                                          gridAutoColumns: '1fr',
                                          gap: 1,
                                        }}>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                            <FormControl>
                                              {`Thread Color : ${emdryItem.keys[keyElement].threadColor.name}`}
                                              
                                            </FormControl>
                                          </Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                            <FormControl>
                                              {`Color Code : ${emdryItem.keys[keyElement].threadColor.value}`}
                                              <span
                                                style={{ backgroundColor: emdryItem.keys[keyElement].threadColor.value, width:"24px", height:"24px" }}
                                              ></span>
                                            </FormControl>
                                          </Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                            <FormControl>
                                              {`${emdryItem.keys[keyElement].threadColor.currency} ${priceFormat(emdryItem.keys[keyElement].threadColor.price)}`}
                                              
                                            </FormControl>
                                          </Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                            <FormControl>
                                              {`${product.totalQuantity} (Qty) X ${emdryItem.keys[keyElement].threadColor.currency} ${priceFormat(emdryItem.keys[keyElement].threadColor.price)} = ${emdryItem.keys[keyElement].threadColor.currency} ${priceFormat(product.totalQuantity * emdryItem.keys[keyElement].threadColor.price)}`}
                                              
                                            </FormControl>
                                          </Grid>

                                        </Grid>
                                      </Box>}
                                      <Box ml={5} mb={2.5}>
                                        <Grid container spacing={5} sx={{
                                          display: 'grid',
                                          gridAutoColumns: '1fr',
                                          gap: 1,
                                        }}>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                            <FormControl>
                                              {`Font Style : ${emdryItem.keys[keyElement].fontStyle.name}`}
                                              
                                            </FormControl>
                                          </Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                            <FormControl>
                                              
                                              <img src={emdryItem.keys[keyElement].fontStyle.image} alt={`Preview`} width={`25%`} />
                                            </FormControl>
                                          </Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                            <FormControl>
                                              {`${emdryItem.keys[keyElement].fontStyle.currency} 0.00`}
                                              
                                            </FormControl>
                                          </Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                            <FormControl>
                                              {`${product.totalQuantity} (Qty) X ${emdryItem.keys[keyElement].fontStyle.currency} 0.00 = ${emdryItem.keys[keyElement].fontStyle.currency} ${priceFormat(product.totalQuantity * 0)}`}
                                              
                                            </FormControl>
                                          </Grid>

                                        </Grid>
                                      </Box>
                                      <Box ml={5} mb={2.5}>
                                        <Grid container spacing={5} sx={{
                                          display: 'grid',
                                          gridAutoColumns: '1fr',
                                          gap: 1,
                                        }}>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                            <FormControl>
                                              {`Position : ${basePrice[0].name?basePrice[0].name:""}`}
                                              
                                            </FormControl>
                                          </Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                                            <FormControl>
                                              {`${emdryItem.keys[keyElement].fontSize.currency} ${priceFormat(basePrice[0].price)}`}
                                              
                                            </FormControl>
                                          </Grid>
                                          <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                                            <FormControl>
                                              {`${product.totalQuantity} (Qty) X ${emdryItem.keys[keyElement].fontSize.currency} ${basePrice[0].price} = ${emdryItem.keys[keyElement].fontSize.currency} ${priceFormat(product.totalQuantity * basePrice[0].price)}`}
                                              
                                            </FormControl>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </>}
                                  </Box>
                                    <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                                  </>
                                }
                                break;
                            }
                          })
                        }
                      </Box>
                    </Box>
                    <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                    {product.customizedProducts.previewImages && Object.keys(product.customizedProducts.previewImages).length > 0 && (
                      <Box ml={0} mb={2.5}>
                        <h4 style={{ "color": "blue" }} >
                          <u>Custom Styling Preview</u>
                        </h4>
                        <Box ml={5} mb={2.5}>
                          <Grid container spacing={5} >
                            {Object.keys(product.customizedProducts.previewImages).map((prevImgName) =>


                              <Grid item >
                                <FormControl>
                                  <strong><InputLabel style={{ position: "absolute", "top": "-10" }}>{prevImgName}</InputLabel></strong>
                                  <img
                                    src={product.customizedProducts.previewImages[prevImgName]}
                                    alt={prevImgName}
                                    width="75%"
                                  />
                                </FormControl>
                              </Grid>


                            )}
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </Box>

                </>
              );
            })}
            <Box ml={5} mb={2.5} mt={2.5}><hr /></Box> */}
            <Box ml={5} mb={2.5}>
              <h3 style={{ "color": "blue" }}>
                <u>Offline Communications</u>
              </h3>
              <Box ml={5} mb={2.5}>
                <Box ml={5} mb={2.5}>
                  {rowData.additionalData?.offlineCommunications && rowData.additionalData?.offlineCommunications.map((file, i) => <>{(i+1)}.&nbsp;<a href={file.content} target="_self" download={file.name}>{file.name}</a>&nbsp;&nbsp;&nbsp;<a href="javascript:void(0);" onClick={() => deleteOfflineCommFile(i)} style={{color:"red"}}>Delete</a><br/></>)}
                </Box>
              </Box>
              <h3 style={{ "color": "blue" }}>
                <u>Upload Files</u>
              </h3>
              <Box ml={5} mb={2.5}>
                <Box ml={5} mb={2.5}>
                <ImperativeFilePicker
                  setCustomFileChange={(fileArr) => {
                  
                    console.log(fileArr);
                    /*(async () => {
                      let uploadedFileArr = [];
                      // upload images 
                      if (Array.isArray(fileArr) && fileArr.length > 0) {
                        for (let file of fileArr) {
                          let f = dataURItoBlob(file.content);
                          //for (let img in color.images) {
                            if (f && typeof f === "object") {
                              const fileRes = await addImage([f]);
                              uploadedFileArr.push(fileRes[0] ?? "");
                            }
                            else if (f) {
                              console.log("file is not object");
                              // do nothing
                            } else {
                              console.log("file is not recognize");
                              // do nothing
                            }
                          //}
                        }
                      }
                      //return colors;
                      console.log("uploaded files : ", uploadedFileArr);
                      setCustomFiles(uploadedFileArr);
                    })();*/
                    setCustomFiles(fileArr);
                  }}
                  files={customFiles}
                />
                </Box>
              </Box>
            </Box>
            <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
            <Box ml={5} mb={2.5}>
              {/* <h3 style={{ "color": "blue" }}>
                <u>Admin Calculation</u>
              </h3> */}
              <Box ml={5} mb={2.5}>
                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Sub Total : </Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <FormControl>
                      <b>{rowData.currency + " " + subTotal}</b>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Discount :</Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder="Promotional Code"
                          disabled={false}
                          value={promotionCode}
                          
                          onChange={(e) => {
                            let val = e.target.value;
                            val = val.length <= 50?val:val.substring(0, 50);

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                promotionCode: val
                              }
                            };

                            console.log(orderObj);
                            
                            /* set RowData */
                            setRowData(orderObj);
                          }}
                        />
                        <span>(max. 50 characters are allowed)</span>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Discount Amount :</Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder=""
                          disabled={false}
                          value={discountAmount}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderDiscount: val
                              },
                              totalAmount: subTotal
                            };
                            console.log(orderObj);
                            
                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          onChange={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            setDiscountAmount(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Total Amount :</Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <FormControl>
                        <b>{rowData.currency + " " + total}</b>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Sales Tax :</Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder=""
                          disabled={false}
                          value={salesTax}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderSalesTax: val
                              },
                              totalAmount: subTotal
                            };
                            console.log(orderObj);
                            
                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          onChange={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            setSalesTax(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>County Tax :</Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder=""
                          disabled={false}
                          value={countryTax}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderCountryTax: val
                              },
                              totalAmount: subTotal
                            };
                            console.log(orderObj);
                            
                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          onChange={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            setCountryTax(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Rush Fee :</Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder=""
                          disabled={false}
                          value={rushFee}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderRushFeeTax: val
                              },
                              totalAmount: subTotal
                            };
                            console.log(orderObj);

                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          onChange={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            setRushFee(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder="Greek Licensing Fee Text:"
                          disabled={false}
                          value={greekLicensingFeeText}
                          onChange={(e) => {
                            let val = e.target.value;

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderGreekLicensingFeeText: val
                              }
                            };

                            console.log(orderObj);
                            //setGreekLicensingFeeText(val);
                            //setNetAmount(netAmountVal);
                            /* set RowData */
                            setRowData(orderObj);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder=""
                          disabled={false}
                          value={greekLicensingFee}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderGreekLicensingFee: val
                              },
                              totalAmount: subTotal
                            };
                            console.log(orderObj);

                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          onChange={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            setGreekLicensingFee(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder="Greek Licensing Fee 2 Text:"
                          disabled={false}
                          value={greekLicensingFee2Text}
                          onChange={(e) => {
                            let val = e.target.value;

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderGreekLicensingFee2Text: val
                              }
                            };

                            console.log(orderObj);
                            //setGreekLicensingFee2Text(val);
                            //setNetAmount(netAmountVal);
                            /* set RowData */
                            setRowData(orderObj);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder=""
                          disabled={false}
                          value={greekLicensingFee2}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderGreekLicensingFee2: val
                              },
                              totalAmount: subTotal
                            };
                            console.log(orderObj);

                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          onChange={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            setGreekLicensingFee2(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder="Greek Licensing Fee 3 Text:"
                          disabled={false}
                          value={greekLicensingFee3Text}
                          onChange={(e) => {
                            let val = e.target.value;

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderGreekLicensingFee3Text: val
                              }
                            };

                            console.log(orderObj);
                            //setGreekLicensingFee3Text(val);
                            //setNetAmount(netAmountVal);
                            /* set RowData */
                            setRowData(orderObj);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder=""
                          disabled={false}
                          value={greekLicensingFee3}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderGreekLicensingFee3: val
                              },
                              totalAmount: subTotal
                            };
                            console.log(orderObj);

                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          onChange={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            setGreekLicensingFee3(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>


                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Additional charges :</Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder=""
                          disabled={false}
                          value={chargeAmount}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderAdditionalCharge: val
                              },
                              totalAmount: subTotal
                            };
                            console.log(orderObj);
                            
                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          onChange={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            setChargeAmount(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Shipping charges <br /> 
                      <select onChange={(e) => handleShipping(e.target.value)}>
                        {
                          shippingList.map((itm) => <option selected={shippingType.toLowerCase() == itm.type.toLowerCase()} value={JSON.stringify(itm)}>{itm.type}</option>)
                        }
                      </select> :
                    </Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        <TextField
                          fullWidth
                          label=""
                          placeholder=""
                          disabled={false}
                          value={shippingCharge}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderShippingCharge: val
                              },
                              totalAmount: subTotal
                            };
                            console.log(orderObj);

                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          onChange={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            setShippingCharge(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Signature Confirmation<br/>
                    <select onChange={(e) => handleCustomerSignature(e)}>
                      <option selected={customerSignature == true} value="Y">Yes</option>
                      <option selected={customerSignature == false} value="N">No</option>
                    </select>
                    :</Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      {/* <FormControl>
                        {rowData.currency + ` ${customerSignature?"2.00":"0.00"}`}
                      </FormControl> */}
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        
                        <TextField
                          fullWidth
                          label=""
                          placeholder=""
                          disabled={false}
                          value={customerSignatureCharge}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                isPickupSignatureCharge: val
                              },
                              //totalAmount: subTotal
                            };
                            console.log(orderObj);

                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          onChange={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            setCustomerSignatureCharge(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>
                      <FormControl>
                        <TextField
                          fullWidth
                          label="Adjustment Details:"
                          placeholder="Adjustment Details:"
                          disabled={false}
                          value={adjustmentDetailsText}
                          multiline
                          rows={2}
                          maxRows={4}
                          onChange={(e) => {
                            let val = e.target.value;

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderAdjustmentDetailsText: val
                              }
                            };

                            console.log(orderObj);
                            
                            /* set RowData */
                            setRowData(orderObj);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid style={{display:"flex"}} item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <div style={{margin: "20px 10px 0px 0px"}}>{`${rowData.currency}`}</div>
                      <FormControl>
                        <TextField
                          type="number"
                          value={adjustmentPrice}
                          variant="outlined"
                          inputProps={{
                            step: "1"
                          }}
                          onChange={(e) => {
                            console.log("+++++++++++++++++++++++++++++++++++++", e.target.value);
                            //setValue(parseFloat(e.target.value).toFixed(1))
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            console.log("val : "+val);
                            setAdjustmentPrice(val);
                          }}
                          onBlur={(e) => {
                            let val = e.target.value.trim().replace(rowData.currency, "");

                            let orderObj = {
                              ...rowData,
                              additionalData: {
                                ...rowData.additionalData,
                                totalOrderAdjustmentPrice: val
                              },
                              totalAmount: subTotal
                            };
                            console.log(orderObj);

                            /* set RowData */
                            setRowData(orderObj);
                          }}
                          
                        />
                        {/*<NumberInput
                          fullWidth
                          aria-label="Quantity Input"
                          min={1}
                          max={100}
                          label=""
                          placeholder=""
                          disabled={false}
                          
                          onInputChange={(e) => {
                            console.log(e.target);
                            let val = e.target.value.trim().replace(rowData.currency, "");
                            alert(val);
                            console.log("++++++++++++++++++++++++++++++++++++++++++++++++++", val);
                            setAdjustmentPrice(val);
                          }}
                        />*/}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                <Box ml={5} mb={2.5}>
                  <Grid container spacing={5} sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                  }}>
                    <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '3 / 7' }}>Net Amount :</Grid>
                    <Grid item sx={{ gridRow: '1', gridColumn: '4 / 7' }}>
                      <FormControl>
                      <b>{rowData.currency + " " + netAmount}</b>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  const showOrderPrintDetailsNew = (rowData) => {
    console.log("rowData : ", rowData);
    return (<>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
          <Box mb={2.5} style={{width:"100%"}}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <FormControl style={{width:"100%"}}>
                  <div id="previewIframePrintOrder" style={{width:"100%", height:"1000px"}}>

                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
    </>)
  };

  const showOrderPrintDetails = (rowData) => {
    console.log("rowData : ", rowData);
    return (<Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
      <Box ml={5} mb={2.5}>
        {/* Order Information */}
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <h3 style={{ "color": "blue" }}>
              <u>Order Information</u>
            </h3>
          </Grid>
          <Grid item >
              <p>{`OrderId : ${rowData.orderId}`}</p>
              <p>{`Date : ${formatDate(rowData.placedAt)}`}</p>
              <p>{`Accepted/Cancelled : ${rowData.orderAccepted
                    ? "Accepted"
                    : rowData.orderCancelled
                      ? "Cancelled"
                      : ""}`}</p>
              <p>{`Payment Status : ${rowData.status}`}</p>
              {rowData.additionalData && rowData.additionalData.discountCode && <p>{`Discount Code : ${rowData.additionalData.discountCode}`}</p>}
              {rowData.additionalData && rowData.additionalData.isPickupSignatureNeeded && <p>{`Signature Confirmation Needed : ${rowData.additionalData.isPickupSignatureNeeded ? "Yes" : "No"}`}</p>}
              {rowData.additionalData && rowData.additionalData.needByDate && <p>{`Need By Date : ${formatDate(rowData.additionalData.needByDate)}`}</p>}
              {rowData.additionalData && rowData.additionalData.referrel && <p>{`Referrel : ${rowData.additionalData.referrel}`}</p>}
              {rowData.additionalData && rowData.additionalData.shippingType && <p>{`Initial Shipping Type : ${rowData.additionalData.shippingType}`}</p>}
              {rowData.additionalData && rowData.additionalData.textMeAfterQuoteSent && <p>{`Text Me After Quote Sent : ${rowData.additionalData.textMeAfterQuoteSent ? "ACTIVE" : "INACTIVE"}`}</p>}
          </Grid>
          <Grid item >
            
          </Grid>
        </Grid>

        {/* Product List */}
        <Box ml={5} mb={2.5}>
          <Grid item xs={12}>
            <h3 style={{ "color": "blue" }}>
              <u>Product List</u>
            </h3>
          </Grid>
          {rowData.productList.map((product, index) => {
            console.log(product);
            /* let totalPrice = totalPriceCalculation(product);
            product.totalPrice = totalPrice; */

            return (
              <>
                <Box ml={0} mb={2.5}>
                  {product.productId.name == null ? (
                    <h3 style={{ "color": "blue" }}>
                      <u>Product Id : {product.productId}</u>
                    </h3>
                  ) : (
                    <>
                      {/* <h3 style={{ "color": "blue" }}>
                        <u>Product Id : {product.productId._id}</u>
                      </h3> */}
                      <Box ml={5} mb={2.5}>
                        <Grid container spacing={5}>
                          <Grid item>
                              <p>{`Name : `}{wordWrap(product.productId.name, 15)}</p>
                              <p>{`Includes : `}{wordWrap(product.productId.includes, 15)}</p>
                              <p>{`Clearance : ${product.productId.clearance == true?"Yes":"No"}`}</p>
                              <p>{`Base Price: ${product.productId.currency} ${priceFormat(product.productId.price)}`}</p>
                          </Grid>
                          <Grid item >
                            
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                  <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                  <Box ml={5} mb={2.5}>
                    <h4 style={{ "color": "blue" }} >
                      <u>Color</u>
                    </h4>
                    <Box ml={5} mb={2.5}>
                      <Grid container spacing={5}>
                        {product.customizedProducts.colors && (<><Grid item >
                          
                            {`Color Code : ${product.customizedProducts.colors.colorCode}`}
                            <span
                              style={{ backgroundColor: product.customizedProducts.colors.colorCode, width:"24px", height:"24px" }}
                            ></span>
                         
                        </Grid>
                        <Grid item >
                          
                            {`Color Name : ${product.customizedProducts.colors.colorName}`}
                         
                        </Grid></>)}
                      </Grid>
                    </Box>

                  </Box>
                  <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                  <Box ml={5} mb={2.5}>
                    <h4 style={{ "color": "blue" }} >
                      <u>Size&Quantity</u>
                    </h4>
                    <Box ml={5} mb={2.5}>
                      {product.customizedProducts.colors['size&quantity'].map((sizeInfo, sizeInfoIndex) => (<>
                        {`Size : ${sizeInfo.sizeName}`}<br/>{`Qty : ${sizeInfo.quantity}`}<br/>{`Price : ${product.currency} ${priceFormat(sizeInfo.sizePrice)}`}
                        {`Total : ${product.totalQuantity} (Qty) X ${product.currency} ${priceFormat(sizeInfo.sizePrice)} = ${product.currency} ${priceFormat(sizeInfo.quantity * sizeInfo.sizePrice)}`}
                      </>)
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                <Box ml={5} mb={2.5}>
                  {/*{product.customizedProducts.colors && (
                    <Box ml={5} mb={2.5} style={{ width: "100%" }}>
                      <h4 style={{"color":"blue"}} >
                        <u>Colors</u>
                      </h4>
                      <Grid container spacing={5}>
                        
                      </Grid>
                    </Box>
                  )}*/}
                  <Box ml={0} mb={2.5}>
                    <h4 style={{ "color": "blue" }} >
                      <u>Applique</u>
                    </h4>

                    <Box ml={5} mb={2.5}>
                      <h4 style={{ "color": "blue" }} >
                        <u>Front</u>
                      </h4>
                      <Box ml={5} mb={2.5}>
                        <h4 style={{ "color": "blue" }} >
                          <u>Text & Position Info</u>
                        </h4>
                        <Box ml={5} mb={2.5}>
                          <Grid container spacing={5} sx={{
                            display: 'grid',
                            gridAutoColumns: '1fr',
                            gap: 1,
                          }}>

                            <Grid item sx={{ gridRow: '1', gridColumn: '1 / 5' }}>
                                <p>{`Position : ${product.customizedProducts.appliqueId.front.letterPlacement?product.customizedProducts.appliqueId.front.letterPlacement:""}`}</p>
                                <p>{`Letter Size : ${product.customizedProducts.appliqueId.front.letterSize.size}`}</p>
                                <p>{`Organization Text : `}<b>{`${product.customizedProducts.appliqueId.front.organization}`}</b></p>
                                <p>{`Special Instruction : ${product.customizedProducts.appliqueId.front.specialInstruction}`}</p>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      {product.customizedProducts.appliqueId.front
                        .foreground.foregroundColor && typeof product.customizedProducts.appliqueId.front
                          .foreground.foregroundColor.name !== "undefined" && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                      {product.customizedProducts.appliqueId.front
                        .foreground.foregroundColor && typeof product.customizedProducts.appliqueId.front
                          .foreground.foregroundColor.name !== "undefined" && <Box ml={5} mb={2.5}>
                          <h4 style={{ "color": "blue" }} >
                            <u>Forground</u>
                          </h4>
                          <Box ml={5} mb={2.5}>
                            <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>

                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <p>{`Material Name : ${product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.name} - ${product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.price)}`}</p>
                                  <p>{`Material Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.price)} = ${product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.foreground.foregroundMaterial.price)}`}</p>
                                  <p>{`Material Color : ${product.customizedProducts.appliqueId.front.foreground.foregroundColor.name} - ${product.customizedProducts.appliqueId.front.foreground.foregroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.foregroundColor.price)}`}</p>
                                  <p>{`Thread Color Code : ${product.customizedProducts.appliqueId.front.foreground.foregroundColor.value}`}</p>
                                  <p>{`Thread Color Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.foreground.foregroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.foregroundColor.price)} = ${product.customizedProducts.appliqueId.front.foreground.foregroundColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.foreground.foregroundColor.price)}`}</p>
                                  {product.customizedProducts.appliqueId.front.foreground.threadColor && <p>{`Thread Name : ${product.customizedProducts.appliqueId.front.foreground.threadColor.name} - ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.threadColor.price)}`}</p>}
                                  <p>{`Thread Color Code : ${product.customizedProducts.appliqueId.front.foreground.threadColor.value}`}</p>
                                  <p>{`Thread Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.threadColor.price)} = ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.foreground.threadColor.price)}`}</p>
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>

                              </Grid>
                            </Grid>
                          </Box>
                      </Box>}
                      {product.customizedProducts.appliqueId.front
                        .background.backgroundColor && typeof product.customizedProducts.appliqueId.front
                          .background.backgroundColor.name !== "undefined" && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                      {product.customizedProducts.appliqueId.front
                        .background.backgroundColor && typeof product.customizedProducts.appliqueId.front
                          .background.backgroundColor.name !== "undefined" && <Box ml={5} mb={2.5}>
                          <h4 style={{ "color": "blue" }} >
                            <u>Background</u>
                          </h4>

                          <Box ml={5} mb={2.5}>
                            <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>

                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <p>{`Material Name : ${product.customizedProducts.appliqueId.front.background.backgroundMaterial.name} - ${product.customizedProducts.appliqueId.front.background.backgroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.front.background.backgroundMaterial.price)}`}</p>
                                  <p>{`Material Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.background.backgroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.front.background.backgroundMaterial.price)} = ${product.customizedProducts.appliqueId.front.background.backgroundMaterial.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.background.backgroundMaterial.price)}`}</p>
                                  <p>{`Material Color Name : ${product.customizedProducts.appliqueId.front.background.backgroundColor.name} - ${product.customizedProducts.appliqueId.front.background.backgroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.background.backgroundColor.price)}`}</p>
                                  <p>{`Thread Color Code : ${product.customizedProducts.appliqueId.front.background.backgroundColor.value}`}</p>
                                  <p>{`Material Color Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.background.backgroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.background.backgroundColor.price)} = ${product.customizedProducts.appliqueId.front.background.backgroundColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.background.backgroundColor.price)}`}</p>
                                  <p>{`Thread Name : ${product.customizedProducts.appliqueId.front.foreground.threadColor.name} - ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.threadColor.price)}`}</p>
                                  <p>{`Thread Color Code : ${product.customizedProducts.appliqueId.front.foreground.threadColor.value}`}</p>
                                  <p>{`Thread Total :  ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.threadColor.price)} = ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.foreground.threadColor.price)}`}{`${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.front.foreground.threadColor.price)} = ${product.customizedProducts.appliqueId.front.foreground.threadColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.foreground.threadColor.price)}`}</p>
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                
                              </Grid>
                            </Grid>
                          </Box>
                      </Box>}
                      {product.customizedProducts.appliqueId.front
                        .heatSealedLetter.isactive == true && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                      {product.customizedProducts.appliqueId.front
                        .heatSealedLetter.isactive == true && (<Box ml={5} mb={2.5}>
                          <Box ml={5} mb={2.5}>
                            <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>
                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                              {`Heat Sealed Letter - ${product.currency} ${priceFormat("2.00")}`}
                              <br />
                              {`Heat Sealed Total : ${product.totalQuantity} (Qty) X ${product.currency} ${priceFormat("2.00")} = ${product.currency} ${priceFormat(product.totalQuantity * 2.00)}`}
                                
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                              
                            </Grid>
                          </Box>
                        </Box>)}
                      {product.customizedProducts.appliqueId.front
                        .stitchingType && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                      {product.customizedProducts.appliqueId.front
                        .stitchingType && (<Box ml={5} mb={2.5}>
                          <Box ml={5} mb={2.5}>
                            <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>
                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                
                                  {`Stitching Type : ${product.customizedProducts.appliqueId.front.stitchingType.stitchingType} - ${product.customizedProducts.appliqueId.front.stitchingType.currency} ${priceFormat(product.customizedProducts.appliqueId.front.stitchingType.price)}`}
                                  <br />
                                  {`Stitching Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.front.stitchingType.currency} ${priceFormat(product.customizedProducts.appliqueId.front.stitchingType.price)} = ${product.customizedProducts.appliqueId.front.stitchingType.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.front.stitchingType.price)}`}
                                
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                              
                            </Grid>
                          </Box>
                        </Box>)}
                    </Box>
                    <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                    <Box ml={5} mb={2.5}>
                      <h4 style={{ "color": "blue" }} >
                        <u>Back</u>
                      </h4>
                      <Box ml={5} mb={2.5}>
                        <h4 style={{ "color": "blue" }} >
                          <u>Text & Position Info</u>
                        </h4>
                        <Box ml={5} mb={2.5}>
                        <Grid container spacing={5} sx={{
                            display: 'grid',
                            gridAutoColumns: '1fr',
                            gap: 1,
                          }}>

                          <Grid item sx={{ gridRow: '1', gridColumn: '1 / 5' }}>
                              <p>{`Position : ${product.customizedProducts.appliqueId.back.letterPlacement?product.customizedProducts.appliqueId.back.letterPlacement:""}`}</p>
                              <p>{`Letter Size : ${product.customizedProducts.appliqueId.back.letterSize.size}`}</p>
                              <p>{`Organization Text : `}<b>{`${product.customizedProducts.appliqueId.back.organization}`}</b></p>
                              <p>{`Special Instruction : ${product.customizedProducts.appliqueId.back.specialInstruction}`}</p>
                          </Grid>
                          <Grid item  sx={{ gridRow: '1', gridColumn: '2 / 5' }}>
                           
                          </Grid>
                        </Grid>
                        </Box>
                      </Box>
                      {product.customizedProducts.appliqueId.back
                        .foreground.foregroundColor && typeof product.customizedProducts.appliqueId.back
                          .foreground.foregroundColor.name !== "undefined" && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                      {product.customizedProducts.appliqueId.back
                        .foreground.foregroundColor && typeof product.customizedProducts.appliqueId.back
                          .foreground.foregroundColor.name !== "undefined" && <Box ml={5} mb={2.5}>
                          <h4 style={{ "color": "blue" }} >
                            <u>Forground</u>
                          </h4>
                          <Box ml={5} mb={2.5}>
                            <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>

                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <p>{`Material Name : ${product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.name} - ${product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.price)}`}</p>
                                  <p>{`Material Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.price)} = ${product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.foreground.foregroundMaterial.price)}`}</p>
                                  <p>{`Material Color Name : ${product.customizedProducts.appliqueId.back.foreground.foregroundColor.name} - ${product.customizedProducts.appliqueId.back.foreground.foregroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.foregroundColor.price)}`}</p>
                                  <p>{`Material Color Code : ${product.customizedProducts.appliqueId.back.foreground.foregroundColor.value}`}</p>
                                  <p>{`Material Color Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.foreground.foregroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.foregroundColor.price)} = ${product.customizedProducts.appliqueId.back.foreground.foregroundColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.foreground.foregroundColor.price)}`}</p>
                                  <p>{`Thread Name : ${product.customizedProducts.appliqueId.back.foreground.threadColor.name} - ${product.customizedProducts.appliqueId.back.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.threadColor.price)}`}</p>
                                  <p>{`Thread Color Code : ${product.customizedProducts.appliqueId.back.foreground.threadColor.value}`}</p>
                                  <p>{`Thread Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.foreground.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.foreground.threadColor.price)} = ${product.customizedProducts.appliqueId.back.foreground.threadColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.foreground.threadColor.price)}`}</p>
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                
                              </Grid>
                            </Grid>
                          </Box>
                      </Box>}
                      {product.customizedProducts.appliqueId.back
                        .background.backgroundColor && typeof product.customizedProducts.appliqueId.back
                          .background.backgroundColor.name !== "undefined" && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                      {product.customizedProducts.appliqueId.back
                        .background.backgroundColor && typeof product.customizedProducts.appliqueId.back
                          .background.backgroundColor.name !== "undefined" && <Box ml={5} mb={2.5}>
                          <h4 style={{ "color": "blue" }} >
                            <u>Background</u>
                          </h4>

                          <Box ml={5} mb={2.5}>
                            <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>

                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                  <p>{`Material Name : ${product.customizedProducts.appliqueId.back.background.backgroundMaterial.name}`}</p>
                                  <p>{`Material Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.background.backgroundMaterial.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.backgroundMaterial.price)} = ${product.customizedProducts.appliqueId.back.background.backgroundMaterial.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.background.backgroundMaterial.price)}`}</p>
                                  <p>{`Material Color Name : ${product.customizedProducts.appliqueId.back.background.backgroundColor.name} - ${product.customizedProducts.appliqueId.back.background.backgroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.backgroundColor.price)}`}</p>
                                  <p>{`Material Color Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.background.backgroundColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.backgroundColor.price)} = ${product.customizedProducts.appliqueId.back.background.backgroundColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.background.backgroundColor.price)}`}</p>
                                  <p>{`Thread Name : ${product.customizedProducts.appliqueId.back.background.threadColor.name} - ${product.customizedProducts.appliqueId.back.background.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.threadColor.price)}`}</p>
                                  <p>{`Thread Color Code : ${product.customizedProducts.appliqueId.back.background.threadColor.value}`}</p>
                                  <p>{`Thread Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.background.threadColor.currency} ${priceFormat(product.customizedProducts.appliqueId.back.background.threadColor.price)} = ${product.customizedProducts.appliqueId.back.background.threadColor.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.background.threadColor.price)}`}</p>
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                
                              </Grid>
                            </Grid>
                          </Box>
                      </Box>}
                      {product.customizedProducts.appliqueId.back
                        .heatSealedLetter.isactive == true && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                      {product.customizedProducts.appliqueId.back
                        .heatSealedLetter.isactive == true && (<Box ml={5} mb={2.5}>
                          
                          <Box ml={5} mb={2.5}>
                            <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>
                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                               
                                  {`Heat Sealed Letter : ${product.customizedProducts.appliqueId.back.heatSealedLetter.isactive == true ? "Yes" : "No"} - ${product.currency} ${priceFormat("2.00")}`}
                                  <br />
                                  {`Heat Sealed Letter Total : ${product.totalQuantity} (Qty) X ${product.currency} ${priceFormat("2.00")} = ${product.currency} ${priceFormat(product.totalQuantity * 2.00)}`}
                                
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                              
                            </Grid>
                          </Box>
                        </Box>)}
                      {product.customizedProducts.appliqueId.back
                        .stitchingType && <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>}
                      {product.customizedProducts.appliqueId.back
                        .stitchingType && (<Box ml={5} mb={2.5}>
                       
                          <Box ml={5} mb={2.5}>
                            <Grid container spacing={5} sx={{
                              display: 'grid',
                              gridAutoColumns: '1fr',
                              gap: 1,
                            }}>
                              <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                
                                  {`Stitching Type : ${product.customizedProducts.appliqueId.back.stitchingType.stitchingType} - ${product.customizedProducts.appliqueId.back.stitchingType.currency} ${priceFormat(product.customizedProducts.appliqueId.back.stitchingType.price)}`}
                                  <br />
                                  {`Stitching Total : ${product.totalQuantity} (Qty) X ${product.customizedProducts.appliqueId.back.stitchingType.currency} ${priceFormat(product.customizedProducts.appliqueId.back.stitchingType.price)} = ${product.customizedProducts.appliqueId.back.stitchingType.currency} ${priceFormat(product.totalQuantity * product.customizedProducts.appliqueId.back.stitchingType.price)}`}
                               
                              </Grid>
                              <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}></Grid>
                             
                            </Grid>
                          </Box>
                        </Box>)}
                    </Box>
                  </Box>
                  <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                  <Box ml={0} mb={2.5}>
                    <h4 style={{ "color": "blue" }} >
                      <u>Embroidary</u>
                    </h4>
                    <Box ml={5} mb={2.5}>
                      {
                        product.customizedProducts.embroidaryId.map(function (emdryItem) {
                          let type = convertToSideValue(emdryItem);

                          switch (type) {
                            case "crest_and_images":
                              for (const keyElement in emdryItem.keys) {
                                let basePrice = emdryItem.basePrice.filter((itm) => itm.name == keyElement);
                                return <><Box ml={5} mb={2.5}>
                                  <Box ml={5} mb={2.5}>
                                    {(Object.hasOwnProperty.call(emdryItem.keys, keyElement)) && <>
                                      <Grid container spacing={5} sx={{
                                        display: 'grid',
                                        gridAutoColumns: '1fr',
                                        gap: 1,
                                      }}>
                                        <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                          <p>{pascalCase(type.replace(/_/g, " "))+` - ${emdryItem.keys[keyElement].imageSize.currency} ${priceFormat(emdryItem.keys[keyElement].imageSize.price)}`}</p>
                                          <p>{`Image Size : ${emdryItem.keys[keyElement].imageSize.name}`}</p>
                                          <p>{`Location : ${emdryItem.keys[keyElement].embroidaryLocation.name}`}</p>
                                          <p>{`Position : ${basePrice[0].name?basePrice[0].name:""}`}</p>
                                          <p>{`Base Price : ${emdryItem.keys[keyElement].imageSize.currency} ${priceFormat(basePrice[0].price)}`}</p>
                                          <p>{`Base Total : ${product.totalQuantity} (Qty) X ${emdryItem.keys[keyElement].imageSize.currency} ${basePrice[0].price} = ${emdryItem.keys[keyElement].imageSize.currency} ${priceFormat(product.totalQuantity * basePrice[0].price)}`}</p>
                                          <p>{`Total : ${product.totalQuantity} (Qty) X ${emdryItem.keys[keyElement].imageSize.currency} ${emdryItem.keys[keyElement].imageSize.price} = ${emdryItem.keys[keyElement].imageSize.currency} ${priceFormat(product.totalQuantity * emdryItem.keys[keyElement].imageSize.price)}`}</p>
                                        </Grid>
                                        <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                         
                                            
                                          
                                        </Grid>
                                      </Grid>
                                    </>}
                                  </Box>
                                  </Box>
                                  <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                                </>
                              }
                              break;
                            case "chest":
                            case "front":
                            case "back":
                            case "arm_left":
                            case "arm_right":
                            case "left_cuff":
                            case "right_cuff":
                            case "on_collar":
                            case "collar_flipped_up":
                            case "hood":
                            case "hood_forward":
                            case "hood_backward":   
                              for (const keyElement in emdryItem.keys) {
                                let basePrice = emdryItem.basePrice.filter((itm) => itm.name == keyElement);
                                console.log(emdryItem.keys[keyElement]);
                                /* let textLine1 = emdryItem.keys[keyElement].textLine1.value.length;
                                let textLine2 = emdryItem.keys[keyElement].textLine2 ? emdryItem.keys[keyElement].textLine2.value.length : 0;
                                let textLine3 = emdryItem.keys[keyElement].textLine3 ? emdryItem.keys[keyElement].textLine3.value.length : 0; */

                                let fontSizeName = emdryItem.keys[keyElement].fontSize.name;

                                let limitSize = parseInt(fontSizeName.substring(fontSizeName.indexOf("(") + 1, fontSizeName.indexOf("(") + 2));

                                /* let actualTextLine1 = 0;
                                let actualTextLine2 = 0;
                                let actualTextLine3 = 0;
                                switch (true) {
                                  case textLine1 > limitSize: actualTextLine1 = (textLine1 - limitSize); actualTextLine1 = actualTextLine1 > 0 ? actualTextLine1 : 0;
                                  case textLine2 > limitSize: actualTextLine2 = (textLine2 - limitSize); actualTextLine2 = actualTextLine2 > 0 ? actualTextLine2 : 0;
                                  case textLine3 > limitSize: actualTextLine3 = (textLine3 - limitSize); actualTextLine3 = actualTextLine3 > 0 ? actualTextLine3 : 0;
                                }
                                console.log("(textLine1-limitSize) : " + actualTextLine1);
                                console.log("(textLine2-limitSize) : " + actualTextLine2);
                                console.log("(textLine3-limitSize) : " + actualTextLine3); */

                                let textLines = [];
                                let textLineLength = 0;
                                let actualTextLine = 0;
                                for(let itm in emdryItem.keys[keyElement]){
                                  if(itm.includes("text") && emdryItem.keys[keyElement][itm].value != ""){
                                    textLineLength = emdryItem.keys[keyElement][itm] ? emdryItem.keys[keyElement][itm].value.length : 0;
                                    actualTextLine = textLineLength > limitSize?(textLineLength - limitSize):textLineLength;
                                    actualTextLine = actualTextLine > 0?actualTextLine:0;
                                    textLines.push({
                                      value: emdryItem.keys[keyElement][itm].value,
                                      fontSize: emdryItem.keys[keyElement].fontSize.name,
                                      currency: emdryItem.keys[keyElement].fontSize.currency,
                                      price: emdryItem.keys[keyElement].fontSize.price,
                                      actualTextLine: actualTextLine
                                    });
                                  }
                                }
                                console.log("textLines : ", textLines);

                                return <><Box ml={5} mb={2.5}>
                                  <h4 style={{ "color": "blue" }} >
                                    <u>{pascalCase(type.replace(/_/g, " "))+` ${type == "front"?emdryItem.name:""}`}</u>
                                  </h4>
                                  {(Object.hasOwnProperty.call(emdryItem.keys, keyElement)) && <>
                                    
                                    <Box ml={5} mb={2.5}>
                                      <Grid container spacing={5} sx={{
                                        display: 'grid',
                                        gridAutoColumns: '1fr',
                                        gap: 1,
                                      }}>
                                        <Grid item sx={{ gridRow: '1', gridColumn: '1 / 7' }}>
                                            {`Font Size : ${emdryItem.keys[keyElement].fontSize.name}`}
                                            <br />
                                            {
                                              textLines.map((textObj, idx) => (
                                                <>
                                                  {textObj.value && `Text Line ${idx+1} : ${textObj.value} - (${textObj.currency} ${priceFormat(textObj.price)} X ${textObj.actualTextLine})`}
                                                  <br />
                                                  {textObj.value && `Total :  ${product.totalQuantity} (Qty) X ${textObj.currency} ${priceFormat(textObj.actualTextLine * parseFloat(textObj.price))} = ${textObj.currency} ${priceFormat(product.totalQuantity * textObj.actualTextLine * parseFloat(textObj.price))}`}
                                                  <br />
                                                </>
                                              ))
                                            }
                                            <br />
                                            {emdryItem.keys[keyElement].threadColor.active == true && `Thread Color : ${emdryItem.keys[keyElement].threadColor.name} - ${emdryItem.keys[keyElement].threadColor.currency} ${priceFormat(emdryItem.keys[keyElement].threadColor.price)}`}
                                            <br />
                                            {emdryItem.keys[keyElement].threadColor.active == true && `Total : ${product.totalQuantity} (Qty) X ${emdryItem.keys[keyElement].threadColor.currency} ${priceFormat(emdryItem.keys[keyElement].threadColor.price)} = ${emdryItem.keys[keyElement].threadColor.currency} ${priceFormat(product.totalQuantity * emdryItem.keys[keyElement].threadColor.price)}`}
                                            <br />
                                            {emdryItem.keys[keyElement].threadColor.active == true && `Color Code : ${emdryItem.keys[keyElement].threadColor.value}`}
                                            
                                            <br />
                                            {emdryItem.keys[keyElement].fontStyle.name && `Font Style : ${emdryItem.keys[keyElement].fontStyle.name} - ${emdryItem.keys[keyElement].fontStyle.currency} 0.00`}
                                            <br />
                                            {emdryItem.keys[keyElement].fontStyle.name && `Total : ${product.totalQuantity} (Qty) X ${emdryItem.keys[keyElement].fontStyle.currency} 0.00 = ${emdryItem.keys[keyElement].fontStyle.currency} ${priceFormat(product.totalQuantity * 0)}`}
                                            <br />
                                            {`Position : ${basePrice[0].name?basePrice[0].name:""} - ${emdryItem.keys[keyElement].fontSize.currency} ${priceFormat(basePrice[0].price)}`}
                                            <br />
                                            {`${product.totalQuantity} (Qty) X ${emdryItem.keys[keyElement].fontSize.currency} ${basePrice[0].price} = ${emdryItem.keys[keyElement].fontSize.currency} ${priceFormat(product.totalQuantity * basePrice[0].price)}`}
                                        </Grid>
                                        <Grid item sx={{ gridRow: '1', gridColumn: '2 / 7' }}>
                                          
                                        </Grid>
                                        
                                      </Grid>
                                    </Box>
                                  </>}
                                </Box>
                                  <Box ml={5} mb={2.5} mt={2.5}><hr /></Box>
                                </>
                              }
                              break;
                          }
                        })
                      }
                    </Box>
                  </Box>
                </Box>
              </>
            );
          })}
        </Box>
        <Box ml={5} mb={2.5}>
          <Grid item xs={12}>
            <h3 style={{ "color": "blue" }}>
              <u>Billing Address</u>
            </h3>
          </Grid>
          <Grid container spacing={5}>
            <Grid item >forward
              <p>Name : {`${rowData.billingAddress.fullName}`}</p>
              <p>Street : {`${rowData.billingAddress.addressLine1} ${rowData.billingAddress.addressLine2} ${rowData.billingAddress.landmark}`}</p>
              <p>City : {`${rowData.billingAddress.city}`}</p>
              <p>State : {`${rowData.billingAddress.state}`}</p>
              <p>Zipcode : {`${rowData.billingAddress.pinCode}`}</p>
            </Grid>
            <Grid item >
              
            </Grid>
          </Grid>
        </Box>
        <Box ml={5} mb={2.5}>
          <Grid item xs={12}>
            <h3 style={{ "color": "blue" }}>
              <u>Shipping Address</u>
            </h3>
          </Grid>
          <Grid container spacing={5}>
            <Grid item >
              <p>Name : {`${rowData.shippingAddress.fullName}`}</p>
              <p>Street : {`${rowData.shippingAddress.addressLine1} ${rowData.shippingAddress.addressLine2} ${rowData.shippingAddress.landmark}`}</p>
              <p>City : {`${rowData.shippingAddress.city}`}</p>
              <p>State : {`${rowData.shippingAddress.state}`}</p>
              <p>Zipcode : {`${rowData.shippingAddress.pinCode}`}</p>
            </Grid>
            <Grid item >
              
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>)
  }

  const closeModal = () => {
    setModal(false);
    setRowData(null);
  };

  const deleteOfflineCommFile = (removedIndex) => {
    let fileArr = rowData.additionalData.offlineCommunications;
    fileArr.splice(removedIndex, 1);

    let obj = {
      ...rowData,
      additionalData: {
        ...rowData.additionalData,
        offlineCommunications: fileArr
      }
    };

    /* set RowData */
    setRowData(obj);
  }

  const onSubmit = () => {
    let offlineCommunicationsArr = rowData.additionalData && rowData.additionalData.offlineCommunications?rowData.additionalData.offlineCommunications:[];
    customFiles.map((file) => offlineCommunicationsArr.push(file));
    console.log("offlineCommunicationsArr : ", offlineCommunicationsArr);
    /* prepare order data */
    const data = {
      ...rowData,
      _id: rowData._id,
      shippingAddressId: shippingAddress,
      billingAddressId: billingAddress,
      shippingRatesId: typeof rowData.shippingRatesId !== "string" ? "0" : rowData.shippingRatesId,
      additionalData: {
        ...rowData.additionalData,
        offlineCommunications: offlineCommunicationsArr,
        totalOrderQty: totalOrderQty,
        totalOrderDiscount: priceFormat(discountAmount),
        netOrderAmount: priceFormat(netAmount),
        totalAfterDiscount: total
      },
      totalAmount: priceFormat(subTotal)
    };

    /*delete data.shippingRate;
    delete data.shippingAddress;
    delete data.billingAddress;
    delete data.userDetails;
    delete data.orderId;
    delete data.userId;
    delete data.placedAt;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.referenceNumber;
    delete data.promotionCode;
    delete data.referral;
    delete data.__v;*/
    console.log("== PUT DATA == : ", data);
    putData({ data }, { refreshOrders, closeModal });
    setOrderDetailsLoadStatus(false);
    closeModal();
  };

  const getModalTitle = () => {
    if (action === "edit") {
      return "Order Details";
    } else if (action === "print") {
      return "Order Print Preview";
    } else if (action === "add") {
      return "Add Moddule";
    } else {
      return "Delete Moddule";
    }
  };

  const approveHandler = () => {
    const data = {
      _id: rowData._id,
      orderAccepted: true,
    };
    putData({ data }, { refreshOrders, closeModal });
  };

  const rejectHandler = () => {
    const data = {
      _id: rowData._id,
      orderCancelled: true,
    };
    putData({ data }, { refreshOrders, closeModal });
  };

  const handleShipping = (shipOpt) => {
    shipOpt = JSON.parse(shipOpt);

    let orderObj = {
      ...rowData,
      additionalData: {
        ...rowData.additionalData,
        totalOrderShippingType: shipOpt.type,
        totalOrderShippingCharge: shipOpt.cost
      }
    };
    console.log(orderObj);

    /* set RowData */
    setRowData(orderObj);
  }

  const handleCustomerSignature = (e) => {
    let signNeeded = e.target.value == "Y"?true:false;
    console.log("rowData.additionalData.isPickupSignatureCharge : ", rowData.additionalData.isPickupSignatureCharge);
    let signCharge = signNeeded?(typeof rowData.additionalData.isPickupSignatureCharge === "undefined"?2.00:parseFloat(rowData.additionalData.isPickupSignatureCharge)):0.00;
    setCustomerSignatureCharge(signCharge);
    console.log("signCharge : ", signCharge);
    let orderObj = {
      ...rowData,
      additionalData: {
        ...rowData.additionalData,
        isPickupSignatureNeeded: signNeeded,
        isPickupSignatureCharge: signCharge
      }
    };
    console.log(orderObj);

    /* set RowData */
    setRowData(orderObj);
  }

  const priceFormat = (price) => {
    return parseFloat(price).toFixed(2);
  }

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      
      <Grid item xs={12} style={{ paddingTop: "0" }}>
        <Card>
          <CardHeader
            title="Manage Orders"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              <></>
              /*<Button
                variant="contained"
                onClick={() => {
                  setModal(true);
                  setAction("add");
                  reset();
                }}
              >
                Add Module
              </Button>*/
            }
          />
          <div style={{ width: "100%" }}>
            {loader ? "" : <DataGrid
              getRowId={(row) => row._id}
              rows={orders}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />}
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
          <CustomizedDialogs
            showModal={modal}
            closeModal={closeModal}
            modalTitle={getModalTitle()}
            fullScreen={(action === "edit" || action === "print") ? true : false}
          >
            {action === "approve" ? (
              <div>
                <p>Are you sure want to Approve the order?</p>
                <div
                  className="align-right"
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    onClick={approveHandler}
                  >
                    Approve
                  </Button>
                </div>
              </div>
            ) : action === "reject" ? (
              <div>
                <p>Are you sure want to Reject the order?</p>
                <div
                  className="align-right"
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    onClick={rejectHandler}
                  >
                    Reject
                  </Button>
                </div>
              </div>
            ) : (
              <form > {/* onSubmit={handleSubmit(onSubmit)} */}
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    {action !== "print" && rowData != null && showOrderDetails(rowData)}
                    {action === "print" && rowData != null && showOrderPrintDetailsNew(rowData)}
                  </Grid>
                  {action !== "print" && <Grid item xs={12}>

                    <div
                      className="align-right"
                      style={{ display: "flex", justifyContent: "space-evenly", width: "10%", float: "right" }}
                    >
                      <Button type="button" color="primary" variant="contained" onClick={() => window.print()}>
                        Print
                      </Button>
                      <Button type="button" color="primary" variant="contained" onClick={() => onSubmit()}>
                        Update
                      </Button>
                    </div>
                  </Grid>}
                </Grid>
              </form>
            )}
          </CustomizedDialogs>
        )}
    </Grid>
  );
}

const ImperativeFilePicker = ({
  setCustomFileChange,
  files
}) => {
  const {
    openFilePicker,
    filesContent,
    loading,
    errors,
    plainFiles,
    clear,
    removeFileByIndex,
    removeFileByReference,
  } = useImperativeFilePicker({
    readAs: "DataURL",
    accept: "*/*",
    multiple: true,
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      // this callback is always called, even if there are errors
      //console.log("onFilesSelected", plainFiles, filesContent, errors);
    },
    onFilesRejected: ({ errors }) => {
      // this callback is called when there were validation errors
      //console.log("onFilesRejected", errors);
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      // this callback is called when there were no validation errors
      //console.log("onFilesSuccessfullySelected", plainFiles, filesContent);
      //console.log("plainFiles", plainFiles);
      setCustomFileChange(filesContent);
    },
    onFileRemoved: (removedFile, removedIndex) => {
      // this callback is called when a file is removed from the list of selected files
      //console.log("onFileRemoved", removedFile, removedIndex);
    },
    validators: [
      new FileAmountLimitValidator({ max: 10 }),
      new FileTypeValidator(["jpg", "jpeg", "JPEG", "png", "pdf"]), /* , "csv", "xls", "xlsx", "docx", "doc", "ppt", "pptx", "txt" */
      new FileSizeValidator({ maxFileSize: 1.5 * 1024 * 1024 /* 1500 kb */ }),
      new ImageDimensionsValidator({
        //maxHeight: 4000, // in pixels
        //maxWidth: 4000,
        minHeight: 50,
        minWidth: 50,
      }),
    ],
  });

  const removeImage = (e, removedIndex) => {
    e.preventDefault();
    e.stopPropagation();
    // this callback is called when a file is removed from the list of selected files
    //console.log("remove Index", removedIndex);
    let fileArr = files;
    console.log(fileArr);
    console.log("removedIndex :", removedIndex);
    fileArr.splice(removedIndex, 1);
    setCustomFileChange(fileArr);
    clear();
  };

  const openPicker = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openFilePicker();
  }

  const clearAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    clear();
    setCustomFileChange([]);
    return false;
  };

  useEffect(() => {
    console.log("files", files);
    clear();
  }, [files]);

  useEffect(() => {
    console.log("init");
    setCustomFileChange([]);
    clear();
  }, []);

  if (errors.length) {
    console.log(errors);

    return (
      <div>
        <button onClick={(e) => openPicker(e)}>
          Something went wrong, retry!{" "}
        </button>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {errors.map((err, i) => (
            <div key={`err_${i}`}>
              {err.name} : {err.reason}
              {/* e.g. "name":"FileAmountLimitError",
              "reason":"MAX_AMOUNT_OF_FILES_EXCEEDED" */}
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <button onClick={(e) => openPicker(e)}>Select file(s)</button>&nbsp;&nbsp;
      <button onClick={(e) => clearAll(e)}>Remove All</button>
      <br />
      No. of Selected Files:
      {files.length}
      <br />
      {/*Amount of filesContent:
      {filesContent.length}
  <br />*/}
      <div
        style={
          {
            marginBottom: "16px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }
        }
      >
        {files.map((file, i) => (
          <div style={{ margin: "5px" }} key={`files_${i}`}>
            <div
              style={{ display: "flex", alignItems: "center" }}
              key={file.name}
            >
              <div>{file.name}</div>
              <button style={{ marginLeft: 24 }} onClick={(e) => removeImage(e, i)}>
                Remove
              </button>
              {/*<button
              style={{ marginLeft: 24 }}
              onClick={() => removeFileByIndex(i)}
            >
              Remove by index
            </button>*/}
            </div>
            <div>
              {/*appliqueInfo.name == "Imprint" && (
                <img alt={file.name} src={file.content}></img>
              )*/}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Order;
