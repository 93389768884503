import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  MenuItem,
  Grid,
  Select,
  TextField,
  Button,
  RadioGroup,
  InputLabel,
  FormLabel,
  Box,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import CustomizedDialogs from "../ui/modal";
import { useGet, usePost, usePut, useDelete } from "../../hooks/useFetch";
import { USER_API, Role_API, GET, POST, PUT, DELETE } from "../../api/config";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import { formatPhoneNumberToUS } from "../../utils/helper";

function User() {
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [action, setAction] = useState("view");
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);
  const [rValue, setRValue] = useState("");

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getRoleData } = useGet(Role_API[GET], {
    apiCall: "onload",
  });
  const { data: userData, refresh: userModules } = useGet(USER_API[GET], {
    apiCall: "onload",
  });
  const { refresh: postData } = usePost(USER_API[POST]);
  const { refresh: putData } = usePut(USER_API[PUT]);
  const { refresh: delData } = useDelete(USER_API[DELETE]);

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getRoleData) {
      setRoles(getRoleData.data.filter((item) => item.name != "Master") || []);
    }
  }, [getRoleData]);

  useEffect(() => {
    if (userData) {
      setUsers(userData.data || []);
    }
  }, [userData]);

  const roleHandleChange = (event) => {
    setRValue(event.target.value);
  };

  const editRowData = (params) => {
    console.log(params.row);
    const { name, email, phone, status, roleId } = params.row;
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setValue("name", name);
    setValue("email", email);
    setValue("phone", phone);
    //setValue("roleId", roleId);
    setRValue(roleId);
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const columns = [
    { field: "name", headerName: "Name", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "phone",
      headerName: "Phone",
      width: 300,
      renderCell: (params) => {
        const phoneNumber = params.value;
        const formattedPhoneNumber = formatPhoneNumberToUS(phoneNumber);
        return <span>{formattedPhoneNumber}</span>;
      },
    },
    { field: "roleAssigned", headerName: "Assigned Role", width: 300 },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 300,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const closeModal = () => {
    setModal(false);
    setValue("name", "");
    setValue("email", "");
    setValue("phone", "");
    setValue("password", "");
    setRValue("");
  };

  const onSubmit = (formData) => {
    if (action === "add") {
      postData(
        {
          data: {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            password: formData.password,
            roleId: formData.roleId,
            verifyEmail: false,
          },
        },
        { userModules, closeModal }
      );
    } else {
      const data = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone.toString(),
        roleId: formData.roleId,
        _id: rowData._id,
      };
      if (formData.password != "") data["password"] = formData.password;
      putData({ data }, { userModules, closeModal });
    }
  };

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update User";
    } else if (action === "add") {
      return "Add User";
    } else {
      return "Delete User";
    }
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };
    delData({ data }, { userModules, closeModal });
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Users"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add User
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={users}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params._id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    placeholder="Name"
                    {...register("name", { required: true, maxLength: 20 })}
                  />
                  {errors.name && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    placeholder="Email"
                    {...register("email", { required: true, email: true })}
                  />
                  {errors.email && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    placeholder="Phone"
                    {...register("phone", { required: true, number: true })}
                  />
                  {errors.phone && (
                    <span className="error-text">
                      This field is required or invalid character was entered.
                    </span>
                  )}
                </Grid>

                <Grid item xs={12}>
                  {action === "edit" ? (
                    <TextField
                      fullWidth
                      label="Password"
                      placeholder="Password"
                      type="password"
                      {...register("password", { required: false })}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label="Password"
                      placeholder="Password"
                      type="password"
                      {...register("password", { required: true })}
                    />
                  )}
                  {errors.password && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-role">Select Role</InputLabel>
                    <Select
                      labelId="select-role"
                      id="select-role"
                      value={rValue}
                      label="role"
                      {...register("roleId", { required: true })}
                      onChange={roleHandleChange}
                    >
                      {roles.map((role) => (
                        <MenuItem key={role._id} value={role._id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default User;
